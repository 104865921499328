import React, { useEffect, useState } from 'react'
import {
  TabbingSwitch,
  FunctionalToggle,
  ToggledCollapsibleBlock,
  FunctionalButton
} from '../../helperComponents/_components'
import {
  createNewIndicatorCalculated,
  fetchEntityData,
  generateNewScenario,
  getCalculatedFromDataAndMode,
  getCalculatedFromEquation,
  updateEntityIncluded,
  updateEquationCalculatedIndicator
} from '../../utils/fetch'
import IndicatorsAndTrendlinesSearch from '../../helperComponents/IndicatorsAndTrendlinesSearch'
import {
  // retrieveFromCache,
  saveDataToCache
} from '../../utils/functions'
import {
  ITrendline,
  ICollapsiblePanelIndicatorsSwitch,
  IEquationPiece,
  IIncludedId,
  IBasicIndicator,
  ICalculatedIndicator,
  ICombinedIndicatorFormulaAlternativeEquationBlocks,
  IGetCalulatedFromDataAndMode,
  IGetCalculatedFromEquation
} from '../../utils/interfaces'
import {
  AggregationMethod,
  CreateSeriesFromDataAndModeType,
  Frequency,
  IIndicatorSetting,
  IIndicatorsSettings
  // CreateSeriesFromDataAndModeType
} from '../interfaces'
import CombineIndicatorFormula from '../../helperComponents/CombineIndicatorFormula'
import Instructions from './blocks'
import { findDataFrequency } from '../../utils/transformingData'
// // есть индикаторсеттингс и  в них всегда есть нейм и сирис нейм / нейм это лайн индекс нужно для того  чтобы поменять сеттингс /
// // indicatorssetings.name  это просто лайн+индекс
// // indicatorssetings.name = tabbingsw.title
// // по сирис нейм мы находим ссирис
const CollapsiblePanelIndicatorsSwitch = ({
  indicators,
  chartInstanceRef,
  allIndicators,
  initialFrequency
}: ICollapsiblePanelIndicatorsSwitch) => {
  const [selectedIndicator, setSelectedIndicator] = useState<string>(
    indicators[0]
  )
  // eslint-disable-next-line
  const [allIndicatorsState, setAllIndicatorsState] = useState<
    (ITrendline | IBasicIndicator | ICalculatedIndicator)[]
  >(allIndicators || [])
  const [newCalculatedName, setNewCalculatedName] = useState<string>('')
  const [addedIndicators, setAddedIndicators] = useState<string[]>([])
  const [addedIndicatorsToLine, setAddedIndicatorsToLine] = useState<
    {
      fid: string
      included: string[]
    }[]
  >([])
  const [indicatorsDict, setIndicatorsDict] =
    useState<ICombinedIndicatorFormulaAlternativeEquationBlocks>({})

  const defaultSettings: IIndicatorSetting = {
    tabbingSwitchTitle: 'Line 1',
    seriesDisplayTitle: allIndicators.find((ind) => ind.fid === indicators[0])
      ?.title as string,
    fid: indicators[0],
    color: '#7cb5ec',
    width: 1,
    dashStyle: 'Solid',
    yAxis: 'left',
    mode: 'absolute',
    defaultFrequency: initialFrequency,
    currentFrequency: initialFrequency,
    aggregationMethod: 'average'
  }

  const [indicatorsSettings, setIndicatorsSettings] =
    useState<IIndicatorsSettings>({
      [indicators[0]]: defaultSettings
    })

  const [newScenarioName, setNewScenarioName] = useState<string>('')

  const getSeries = (chartInstanceRef: any) => {
    try {
      if (
        !chartInstanceRef ||
        !chartInstanceRef.chartInstance ||
        !chartInstanceRef.chartInstance.series
      ) {
        return []
      }
      const series = chartInstanceRef.chartInstance.series.filter((s: any) => {
        const name = (s.options ? s.options : { name: '' }).name || ''
        return name.match(/Navigator \d+/) === null
      })

      return series
    } catch (error) {
      return []
    }
  }

  const updateCachedGraph = () => {
    // find all the series of the current chart
    const series = getSeries(chartInstanceRef.current as any)
    if (series.length === 0) {
      return false
    }
    const strippedSeries = series.map((s: any) => ({
      name: s.options.name,
      data: s.options.data,
      color: s.options.color,
      type: s.options.type,
      dashStyle: s.options.dashStyle,
      lineWidth: s.options.lineWidth,
      yAxis: s.options.yAxis,
      defaultFrequency: s.options.defaultFrequency,
      currentFrequency: s.options.currentFrequency,
      aggregationMethod: s.options.aggregationMethod
    }))

    const key = `indicatorPageChart-${window.location.search}` // please note this must be clear up every time indicator is fetched in Indicator page
    return saveDataToCache(key, strippedSeries)
  }

  // const setupSettings = () => {
  //   if (!allIndicatorsState) return {}

  //   const frequencies: {
  //     seriesDisplayTitle: string
  //     frequency: Frequency
  //   }[] = []

  //   const series = getSeries(chartInstanceRef.current as any)
  //   // // need to check if there is data to be applied to the chart in case it accidentally rerendered
  //   // const key = `indicatorPageChart-${window.location.search}` // please note this must be clear up every time indicator is fetched in Indicator page
  //   // const retrieved = retrieveFromCache(key)

  //   // if series if not in time to render, we need a default settings to begin
  //   const defaultSettings = tabbingSwitchOptions.reduce((acc, indicator) => {
  //     const matchedIndicator = allIndicatorsState.find(
  //       (ind) => ind.fid === indicator.fid
  //     )

  //     let objectTouse = indicator

  //     if (matchedIndicator) {
  //       objectTouse = {
  //         seriesDisplayTitle: matchedIndicator.title,
  //         tabbingSwitchTitle: `Line ${Object.keys(acc).length + 1}`,
  //         fid: indicator.fid
  //       }
  //     }

  //     const defaultFrequency =
  //       indicator.fid === indicators[0] ? initialFrequency : 'monthly'

  //     const newSetting: IIndicatorSetting = {
  //       tabbingSwitchTitle: objectTouse.tabbingSwitchTitle,
  //       seriesDisplayTitle: objectTouse.seriesDisplayTitle,
  //       color: '#7cb5ec',
  //       dashStyle: 'Solid',
  //       width: 1,
  //       yAxis: 'left',
  //       mode: 'absolute',
  //       defaultFrequency,
  //       currentFrequency: defaultFrequency,
  //       aggregationMethod: 'average'
  //     }

  //     return {
  //       ...acc,
  //       [objectTouse.tabbingSwitchTitle]: newSetting
  //     }
  //   }, {})

  //   if (series.length === 0) {
  //     return defaultSettings
  //   }

  //   series.forEach((s: any) => {
  //     const frequency =
  //       findDataFrequency(
  //         s.options.data.map((d: number[]) => new Date(d[0]))
  //       ) || 'monthly'

  //     frequencies.push({
  //       seriesDisplayTitle: s.name,
  //       frequency: frequency || 'monthly'
  //     })
  //   })

  //   const newSettings = tabbingSwitchOptions.reduce((acc, indicator) => {
  //     const matchedIndicator = allIndicatorsState.find(
  //       (ind) => ind.fid === indicator.fid
  //     )

  //     let objectTouse = indicator

  //     const defaultFrequency =
  //       indicator.fid === indicators[0] ? initialFrequency : 'monthly'

  //     if (matchedIndicator) {
  //       objectTouse = {
  //         seriesDisplayTitle: matchedIndicator.title,
  //         tabbingSwitchTitle: `Line ${Object.keys(acc).length + 1}`,
  //         fid: indicator.fid
  //       }
  //     }

  //     const newSetting: IIndicatorSetting = {
  //       tabbingSwitchTitle: objectTouse.tabbingSwitchTitle,
  //       seriesDisplayTitle: objectTouse.seriesDisplayTitle,
  //       color: '#7cb5ec',
  //       dashStyle: 'Solid',
  //       width: 1,
  //       yAxis: 'left',
  //       mode: 'absolute',
  //       defaultFrequency,
  //       currentFrequency: defaultFrequency,
  //       aggregationMethod: 'average'
  //     }

  //     return {
  //       ...acc,
  //       [objectTouse.tabbingSwitchTitle]: newSetting
  //     }
  //   }, {})

  //   console.log(newSettings)

  //   return newSettings
  // }

  const addExternalFull = async (fid: string) => {
    // try retrieving from backend anyway
    const result = await fetchEntityData({
      fid,
      type: 'external',
      title: '',
      frequency: ''
    })

    const series = result.series
    const params = result.params[0]
    setAllIndicatorsState((prev) => [...prev, params])

    return { series, params }
  }

  const handleAddIndicator = async (
    indicatorFid: string,
    dontUpdateChart = false
  ) => {
    try {
      const matchedIndicator = allIndicatorsState?.find(
        (ind) => ind.fid === indicatorFid
      )

      const newTabbingSwitchTitle = `Line ${Object.keys(indicatorsSettings).length + 1}`

      const newSeriesDisplayTitle =
        matchedIndicator?.title || newTabbingSwitchTitle

      let series
      let newTabbingOptionsObject = {
        fid: indicatorFid,
        seriesDisplayTitle: newSeriesDisplayTitle,
        tabbingSwitchTitle: newTabbingSwitchTitle
      }
      // if indicator not found, we assume its external
      if (!matchedIndicator || matchedIndicator.type === 'external') {
        const retrieved = await addExternalFull(indicatorFid)

        series = retrieved.series
        if (!dontUpdateChart) {
          newTabbingOptionsObject = {
            fid: retrieved.params.fid,
            seriesDisplayTitle: retrieved.params.title,
            tabbingSwitchTitle: newTabbingSwitchTitle
          }
        }
        // in alll other cases, we first check if the function requires an update to the chart and tabs
      } else if (!dontUpdateChart) {
        const { fid, title, type, frequency } = matchedIndicator

        const result = await fetchEntityData({ fid, title, type, frequency })
        series = result.series
        newTabbingOptionsObject = {
          fid,
          seriesDisplayTitle: title,
          tabbingSwitchTitle: newTabbingSwitchTitle
        }
      }

      if (!dontUpdateChart) {
        if (!series) {
          return false
        }

        // const frequency = findDataFrequency(
        //   series.map((d: number[]) => new Date(d[0]))
        // ) as Frequency
        const frequency =
          newTabbingOptionsObject.fid === indicators[0]
            ? initialFrequency
            : 'monthly'

        // before removing, read the color, width, dashstyle, yAxis, mode, defaultFrequency, currentFrequency, aggregationMethod from the series
        let newParams: IIndicatorSetting = {
          tabbingSwitchTitle: newTabbingOptionsObject.tabbingSwitchTitle,
          seriesDisplayTitle: newTabbingOptionsObject.seriesDisplayTitle,
          color: '#7cb5ec',
          width: 1,
          dashStyle: 'Solid',
          fid: newTabbingOptionsObject.fid,
          yAxis: 'left',
          mode: 'absolute',
          defaultFrequency: frequency,
          currentFrequency: frequency,
          aggregationMethod: 'average'
        }

        if (indicatorsSettings && indicatorsSettings[indicatorFid]) {
          const {
            color,
            width,
            dashStyle,
            yAxis,
            mode,
            defaultFrequency,
            currentFrequency,
            aggregationMethod,
            seriesDisplayTitle,
            tabbingSwitchTitle
          } = indicatorsSettings[indicatorFid]

          newParams = {
            tabbingSwitchTitle,
            seriesDisplayTitle,
            color: color || '#7cb5ec',
            width: width || 1,
            dashStyle: dashStyle || 'Solid',
            yAxis: yAxis || 'left',
            fid: indicatorFid,
            mode: mode || 'absolute',
            defaultFrequency: defaultFrequency || initialFrequency,
            currentFrequency: currentFrequency || initialFrequency,
            aggregationMethod: aggregationMethod || 'average'
          }
        }

        setIndicatorsSettings((prevSettings) => ({
          ...prevSettings,
          [newTabbingOptionsObject.fid]: newParams
        }))
        setAddedIndicators((prevIndicators) => [
          ...prevIndicators,
          indicatorFid
        ])
        ;(chartInstanceRef.current as any).chartInstance!.addSeries(series)
      }

      if (dontUpdateChart) {
        const matched = addedIndicatorsToLine.find(
          (_) => _.fid === selectedIndicator
        )

        setAddedIndicatorsToLine((prev) => [
          ...prev,
          {
            fid: selectedIndicator,
            included: [...(matched?.included || []), indicatorFid]
          }
        ])
      }

      return dontUpdateChart ? true : updateCachedGraph()
    } catch (error) {
      console.error('Error adding indicator to chart', error)
      return false
    }
  }

  const handleRemoveIndicator = (indicator: string) => {
    try {
      setAddedIndicators((prevIndicators) =>
        prevIndicators.filter((ind) => ind !== indicator)
      )

      const matchedIndicator = allIndicatorsState?.find(
        (ind) => ind.fid === indicator
      )
      if (!matchedIndicator) return false

      const matchedSeries = (
        chartInstanceRef.current as any
      ).chartInstance.series.find(
        (series: any) => series.name === matchedIndicator.title
      )

      if (!matchedSeries) {
        return false
      }

      matchedSeries.remove()

      return updateCachedGraph()
    } catch (error) {
      console.error('Error removing indicator from chart', error)
    }
  }

  const findSettingsByFid = (fid: string): IIndicatorSetting | null => {
    const settingsObject = indicatorsSettings[fid]

    if (!settingsObject) {
      return null
    }

    return settingsObject
  }

  const findSettingsByTabbingSwitchTitle = (
    tabbingSwitchTitle: string
  ): IIndicatorSetting | null => {
    const settingsObject = Object.values(indicatorsSettings).find(
      (setting) => setting.tabbingSwitchTitle === tabbingSwitchTitle
    )

    if (!settingsObject) {
      return null
    }

    return settingsObject
  }

  const formatSelectedChartSeries = (
    propsToChange: Partial<IIndicatorSetting>
  ) => {
    try {
      const series = getSeries(chartInstanceRef.current as any)
      if (series.length === 0) {
        return false
      }

      const matchedSettings = findSettingsByFid(selectedIndicator)

      if (!matchedSettings) {
        return false
      }

      const { seriesDisplayTitle, tabbingSwitchTitle } = matchedSettings

      let matchedSeries = series.find(
        (series: any) => series.userOptions.name === seriesDisplayTitle
      )

      if (!matchedSeries) {
        matchedSeries = series.find(
          (series: any) => series.userOptions.name === tabbingSwitchTitle
        )

        if (!matchedSeries) {
          console.error('Error finding series by name', {
            seriesDisplayTitle,
            series
          })
          return false
        }
      }

      if (propsToChange.seriesDisplayTitle) {
        matchedSeries.update({
          name: propsToChange.seriesDisplayTitle
        })
      }

      if (propsToChange.color) {
        matchedSeries.update({
          color: propsToChange.color
        })
      }

      if (propsToChange.dashStyle) {
        matchedSeries.update({
          dashStyle: propsToChange.dashStyle
        })
      }

      if (propsToChange.width) {
        matchedSeries.update({
          lineWidth: propsToChange.width
        })
      }

      if (propsToChange.yAxis) {
        matchedSeries.update({
          yAxis: propsToChange.yAxis === 'right' ? 1 : 0
        })
      }

      return true
    } catch (error) {
      console.error('Error formatting series by name', error)
      return false
    }
  }

  const handleFormatSelectedSeries = (
    newSettings: Partial<IIndicatorSetting>
  ) => {
    try {
      // if (!Object.keys(settings).length) {
      //   const setup = setupSettings()
      //   if (!setup) {
      //     return false
      //   }

      //   settings = setup
      // }
      const matchedSettings = findSettingsByFid(selectedIndicator)

      if (!matchedSettings) {
        return false
      }

      setIndicatorsSettings((prevSettings) => ({
        ...prevSettings,
        [selectedIndicator]: {
          ...matchedSettings,
          ...newSettings
        }
      }))

      const updated = formatSelectedChartSeries({ ...newSettings })

      if (updated) {
        return updateCachedGraph()
      }

      return false
    } catch (error) {
      console.error('Error formatting series', error)
      return false
    }
  }

  const updateSelectedIndicatorSeries = async ({
    propsForModeData,
    propsForEquation
  }: {
    propsForModeData?: IGetCalulatedFromDataAndMode
    propsForEquation?: IGetCalculatedFromEquation
  }) => {
    let newSeries
    let propsSeriesTabbingSwitchTitle = ''
    if (propsForModeData) {
      newSeries = await getCalculatedFromDataAndMode(propsForModeData)
      propsSeriesTabbingSwitchTitle = propsForModeData.title
    }

    if (propsForEquation) {
      newSeries = await getCalculatedFromEquation(propsForEquation)
      propsSeriesTabbingSwitchTitle = propsForEquation.title
    }

    if (!newSeries || Object.keys(newSeries).length === 0) {
      return false
    }

    let matchedSettings = findSettingsByTabbingSwitchTitle(
      propsSeriesTabbingSwitchTitle
    )

    if (!matchedSettings || matchedSettings === null) {
      return false
    }

    const { seriesDisplayTitle, tabbingSwitchTitle } = matchedSettings

    // replace the existing series with the new series
    // remove the existing calculated series
    const existingSeries = getSeries(chartInstanceRef.current as any).find(
      (s: any) => s.name === tabbingSwitchTitle || s.name === seriesDisplayTitle
    )

    let mode, defaultFrequency, aggregationMethod // , dataMode,

    const {
      mode: modeFromSettings,
      defaultFrequency: defaultFrequencyFromSettings,
      aggregationMethod: aggregationMethodFromSettings
    } = matchedSettings

    if (propsForModeData) {
      mode = propsForModeData.mode
      // dataMode = propsForModeData.dataMode
      defaultFrequency = propsForModeData.frequency
      aggregationMethod =
        propsForModeData.aggregationMethod as AggregationMethod
    } else {
      mode = modeFromSettings
      defaultFrequency = defaultFrequencyFromSettings
      aggregationMethod = aggregationMethodFromSettings
    }

    const frequency = findDataFrequency(
      newSeries.data.map((d: number[]) => new Date(d[0]))
    ) as Frequency

    matchedSettings = {
      ...matchedSettings,
      seriesDisplayTitle: tabbingSwitchTitle,
      aggregationMethod,
      defaultFrequency,
      mode,
      currentFrequency: frequency
    }

    if (existingSeries) {
      existingSeries.remove()
    }

    // Add the new series to the chart
    ;(chartInstanceRef.current as any).chartInstance.addSeries(newSeries)

    formatSelectedChartSeries({
      ...matchedSettings
    })

    return updateCachedGraph()
  }

  const handleCalculateNewSeriesFromEquation = async (
    equation: IEquationPiece[]
  ) => {
    try {
      const settings = findSettingsByFid(selectedIndicator)
      if (!settings) return false

      const result = await updateSelectedIndicatorSeries({
        propsForEquation: {
          equation,
          title: settings.tabbingSwitchTitle,
          frequency: 'monthly'
        }
      })

      return result
    } catch (error) {
      console.error('Error calculating new series from equation', error)
      return false
    }
  }

  const handleCalculateNewSeriesFromDataAndMode = async ({
    aggregationMethod,
    newFrequency,
    fid = selectedIndicator,
    mode = 'absolute',
    dataMode = 'values'
  }: {
    aggregationMethod: string
    newFrequency: Frequency
    fid?: string
    mode?: CreateSeriesFromDataAndModeType
    dataMode?: 'values' | 'deviations'
  }) => {
    const indicator = allIndicatorsState.find((ind) => ind.fid === fid)

    const settings = findSettingsByFid(fid)
    if (!settings) return false

    return await updateSelectedIndicatorSeries({
      propsForModeData: {
        type: indicator?.type || 'external',
        title: settings.tabbingSwitchTitle,
        fid,
        mode,
        frequency: newFrequency,
        aggregationMethod,
        dataMode
      }
    })
  }

  const handleFrequencyChangeForSelectedIndicator = async (
    newFrequency: Frequency
  ) => {
    const settings = findSettingsByFid(selectedIndicator)
    if (!settings) return false

    const isCalculationSuccessful =
      await handleCalculateNewSeriesFromDataAndMode({
        aggregationMethod: settings ? settings.aggregationMethod : 'average',
        newFrequency
      })
    if (!isCalculationSuccessful) {
      return false
    }

    const newSettings = {
      ...settings,
      currentFrequency: newFrequency
    }

    setIndicatorsSettings((prevSettings) => ({
      ...prevSettings,
      [selectedIndicator]: newSettings
    }))

    return true
  }

  const handleAggregationChangeForSelectedIndicator = async (
    aggregationMethod: AggregationMethod
  ) => {
    const settings = findSettingsByFid(selectedIndicator)
    if (!settings) return false

    const isCalculationSuccessful =
      await handleCalculateNewSeriesFromDataAndMode({
        aggregationMethod,
        newFrequency: settings.currentFrequency || 'monthly'
      })

    if (!isCalculationSuccessful) {
      return false
    }

    const newSettings = {
      ...settings,
      aggregationMethod
    }

    setIndicatorsSettings((prevSettings) => ({
      ...prevSettings,
      [selectedIndicator]: newSettings
    }))

    return true
  }

  const handleCreateNewCalculated = async (
    equation: IEquationPiece[],
    alternativeEquation: IEquationPiece[]
  ) => {
    const equationToUse =
      equation && equation.length > 0 ? equation : alternativeEquation
    if (
      !newCalculatedName ||
      newCalculatedName.length === 0 ||
      !equationToUse ||
      equationToUse.length === 0
    ) {
      return false
    }

    const fid = await createNewIndicatorCalculated({
      title: newCalculatedName,
      dataMode: 'values',
      computationMode: 'calculated',
      noRefresh: true
    })

    if (!fid) {
      return false
    }

    const updated = await updateEquationCalculatedIndicator({
      indicatorId: fid,
      equation: equationToUse
    })

    if (!updated) {
      return false
    }

    window.switchFunctions.calculated(fid)

    return true
  }

  const handleCreateNewScenario = async (): Promise<string | null> => {
    if (newScenarioName.length === 0) {
      return null
    }

    const fid = await generateNewScenario({
      scenarioName: newScenarioName,
      scenarioOverview: 'My new scenario',
      newScenarioPublic: false,
      noRefresh: true
    })

    return fid || null
  }

  const handleSubmitTrendlinesSelection = async (entityId: string) => {
    const scenarioIndicators = [...addedIndicators, ...indicators]

    const formattedScenarioIndicators: IIncludedId[] = scenarioIndicators
      .map((indicator) => {
        const foundIndicator = allIndicators.find(
          (allIndicator) => allIndicator.fid === indicator
        )

        if (!foundIndicator || !foundIndicator.type) return null

        return {
          fid: indicator,
          type: foundIndicator.type,
          chart: true,
          data: true
        }
      })
      .filter((indicator): indicator is IIncludedId => indicator !== null)

    const res = await updateEntityIncluded({
      entity: {
        entityId,
        entityType: 'scenario'
      },
      ids: formattedScenarioIndicators
    })

    return res
  }

  const handleUpdateScenario = async () => {
    const fid = await handleCreateNewScenario()

    if (fid) {
      return handleSubmitTrendlinesSelection(fid)
    }

    return false
  }

  useEffect(() => {
    if (!allIndicatorsState) return

    const result: any = {}

    const keys = 'abcdefghijklmnopqrstuvwxyz'.split('')

    const allIndicators: string[] = [
      selectedIndicator,
      addedIndicatorsToLine
        .filter((_) => _.fid === selectedIndicator)
        .map((_) => _.included)
        .flat()
    ].flat()

    allIndicators.forEach((indicator, index) => {
      const matchedIndicator = allIndicatorsState.find(
        (ind) => ind.fid === indicator
      )

      const matched = matchedIndicator?.title || indicator
      result[keys[index]] = {
        fid: indicator,
        title: matched
      }
    })
    setIndicatorsDict(result)
  }, [selectedIndicator, addedIndicatorsToLine])

  return (
    <div className="flex col-12">
      <div className="col-11 p-2 ps-0">
        <IndicatorsAndTrendlinesSearch
          indicators={(allIndicatorsState as ITrendline[]) || []}
          includedIndicators={addedIndicators}
          refreshIndicators={() => {}}
          direction="down"
          hoverMode
          customMessage="Add Lines to Chart"
          addFunction={(indicator) => handleAddIndicator(indicator)}
          removeFunction={(indicator: string) =>
            handleRemoveIndicator(indicator)
          }
          altFunction={async (id: string) => {}}
        />
      </div>
      <div className="flex col-12 p-2">
        <TabbingSwitch
          numberVisible={4}
          className="p-2 col-12"
          tabbingLook
          options={Object.entries(indicatorsSettings).map(([key, value]) => ({
            label: value.tabbingSwitchTitle,
            active: selectedIndicator === key,
            onClick: () => setSelectedIndicator(key)
          }))}
        />
        <hr />
        <div className="col-12 p-2 collapsible-side-panel-body">
          {Object.entries(indicatorsSettings).map(([key, value], index) => (
            <div key={index} className="flex-column space-between col-12">
              {selectedIndicator === key && (
                <div className="col-12 my-2 gap-3 flex-column">
                  <ToggledCollapsibleBlock
                    title="Change Series"
                    initialState={true}
                    className="t-small-children"
                  >
                    <div className="flex col-12 gap-2 mb-2">
                      <span>Frequency:</span>
                      <select
                        className="inline mx-0"
                        onChange={(e) =>
                          handleFrequencyChangeForSelectedIndicator(
                            e.target.value as Frequency
                          )
                        }
                      >
                        {(value
                          ? value.defaultFrequency === 'daily'
                          : initialFrequency === 'daily') && (
                          <option value="daily">Daily</option>
                        )}
                        {((value
                          ? value.defaultFrequency === 'daily'
                          : initialFrequency === 'daily') ||
                          (value
                            ? value.defaultFrequency === 'weekly'
                            : initialFrequency === 'weekly')) && (
                          <option value="weekly">Weeky</option>
                        )}
                        <option value="monthly">Monthly</option>
                        <option value="quarterly">Quarterly</option>
                        <option value="yearly">Yearly</option>
                      </select>
                      {(value ? value.defaultFrequency : initialFrequency) !==
                        value?.currentFrequency && (
                        <>
                          <span className="ms-3">Aggregation Method:</span>
                          <select
                            value={value ? value.aggregationMethod : 'average'}
                            className="inline mx-0"
                            onChange={(e) => {
                              handleAggregationChangeForSelectedIndicator(
                                e.target.value as AggregationMethod
                              )
                            }}
                          >
                            <option value="average">Average</option>
                            <option value="sum">Sum</option>
                            <option value="first">Start of Period</option>
                            <option value="last">End of Period</option>
                          </select>
                        </>
                      )}
                    </div>
                  </ToggledCollapsibleBlock>
                  <ToggledCollapsibleBlock
                    title="Format Series"
                    initialState={true}
                    className="t-small-children"
                  >
                    <div className="col-12 t-small">
                      <div className="flex space-between col-12 my-2">
                        <div className="flex col-6 py-2">
                          <label>Color:</label>
                          <input
                            type="color"
                            value={value ? value.color : '#7cb5ec'}
                            onChange={(e) => {
                              handleFormatSelectedSeries({
                                color: e.target.value
                              })
                            }}
                          />
                        </div>
                        <div className="flex col-6 py-2">
                          <label>Line Width:</label>
                          <input
                            className="inline"
                            type="number"
                            value={value ? value.width : 1}
                            onChange={(e) => {
                              handleFormatSelectedSeries({
                                width: Number(e.target.value)
                              })
                            }}
                            max={15}
                          />
                        </div>
                        <div className="flex col-6 py-2">
                          <label>Line Style:</label>
                          <select
                            value={value ? value.dashStyle : 'Solid'}
                            className="inline"
                            onChange={(e) =>
                              handleFormatSelectedSeries({
                                dashStyle: e.target.value
                              })
                            }
                          >
                            <option value="Solid">Solid</option>
                            <option value="Dash">Dash</option>
                            <option value="Dot">Dot</option>
                          </select>
                        </div>
                        <div className="flex col-6">
                          <label className="margin-right-1">
                            Preferred Y-Axis:
                          </label>
                          <FunctionalToggle
                            leftTitle="Left"
                            rightTitle="Right"
                            state={value.yAxis === 'right'}
                            functionToExecute={() => {
                              const currentYAxis = value.yAxis
                              const newYAxis =
                                currentYAxis === 'left' ? 'right' : 'left'

                              handleFormatSelectedSeries({
                                yAxis: newYAxis
                              })
                            }}
                            disabled={false}
                          />
                        </div>
                      </div>
                    </div>
                  </ToggledCollapsibleBlock>
                  <ToggledCollapsibleBlock title="Calculate Series">
                    <div className="my-2">
                      <IndicatorsAndTrendlinesSearch
                        indicators={(allIndicatorsState as ITrendline[]) || []}
                        customMessage="Add Lines to Equation"
                        includedIndicators={addedIndicatorsToLine
                          .map((_) => _.included)
                          .flat()}
                        refreshIndicators={() => {}}
                        direction="down"
                        inline
                        addFunction={(indicator) =>
                          handleAddIndicator(indicator, true)
                        }
                        removeFunction={(indicator: string) =>
                          handleRemoveIndicator(indicator)
                        }
                        altFunction={async (id: string) => {}}
                      />
                    </div>

                    <CombineIndicatorFormula
                      indicators={allIndicatorsState}
                      indicatorId={''}
                      refreshFunction={() => {}}
                      updateEquationFunction={
                        handleCalculateNewSeriesFromEquation
                      }
                      alternativeMode={{
                        mode: 'ab',
                        indicatorsDict
                      }}
                      customFunctionalButton={{
                        functionToExecute: handleCreateNewCalculated,
                        title: 'Save as a new calculated',
                        className: 'primary wider',
                        children: (
                          <input
                            type="text"
                            value={newCalculatedName}
                            onChange={(e) =>
                              setNewCalculatedName(e.target.value)
                            }
                            className="wide p-2"
                            placeholder="New Calculated Name"
                          />
                        )
                      }}
                    >
                      <ToggledCollapsibleBlock
                        title="Supported Equation Syntax Guide"
                        className="info"
                      >
                        <Instructions />
                      </ToggledCollapsibleBlock>
                    </CombineIndicatorFormula>
                  </ToggledCollapsibleBlock>
                  <ToggledCollapsibleBlock
                    title="Convert To Scenario"
                    className="d-none" // hide it for a s econd
                  >
                    <div className="flex col-12 gap-2">
                      <input
                        type="text"
                        value={newScenarioName}
                        onChange={(e) => setNewScenarioName(e.target.value)}
                        placeholder="Scenario Name"
                      />
                      <FunctionalButton
                        className="primary"
                        functionToExecute={handleUpdateScenario}
                        initialButtonState="Submit"
                        iconSaveMode
                        doesReset
                        disabled={newScenarioName.length === 0}
                      />
                    </div>
                  </ToggledCollapsibleBlock>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CollapsiblePanelIndicatorsSwitch
