import React, { useEffect, useState } from 'react'
import {
  CreateTrendlineProps,
  ICalculateDeviationsParamsReturnParams,
  IRow,
  ITrendline
} from '../utils/interfaces'
import { HighChartsLinearChart } from '../charts/HighChartsReact'
import { FunctionalButton, Spinner } from '../helperComponents/_components'
import RangeSlider2 from '../helperComponents/RangeSlider2'
import {
  findFrequencyFromTheName,
  isEmpty,
  transformFetchedRangedDetailsScenariotoRangeInputs
} from '../utils/functions'
import { Checkbox } from 'pretty-checkbox-react'
import { calculateDeviationsParams, createNewTrendline } from '../utils/fetch'

const CreateTrendline = ({
  fetchedDataTransformed,
  fetchedIndicatorParameters,
  indicatorId,
  source_fid,
  source,
  closeModal,
  fullDates
}: CreateTrendlineProps) => {
  const [data, setData] = useState<IRow[]>([])
  const [calculatedParams, setCalculatedParams] =
    useState<ICalculateDeviationsParamsReturnParams>({
      trendline: {} as ITrendline,
      a: 0,
      b: 0,
      deviation: 0,
      AAGR: 0,
      keys: {
        valuesTitle: '',
        trendlineTitle: '',
        residualTitle: '',
        residualOverDevTitle: ''
      }
    })
  const [computationMode, setComputationMode] = useState<string>(
    fetchedIndicatorParameters.standard_deviation_chart_mode || 'exponential'
  )
  const [selectedDataBoundaries, setSelectedDataBoundaries] = useState<
    string[]
  >([])

  const handleCalculateTrendlineParameters = async (
    parameters: Partial<ITrendline>
  ) => {
    const firstDate = new Date(fullDates[0])
    const lastDate = new Date(fullDates[fullDates.length - 1])
    const newDateString = `[${firstDate.toISOString().split('T')[0]},${lastDate.toISOString().split('T')[0]})`

    if (isEmpty(parameters.range_chosen)) {
      parameters.range_chosen = newDateString
    }

    if (isEmpty(parameters.range_chosen_deviation)) {
      parameters.range_chosen_deviation = newDateString
    }

    if (isEmpty(parameters.range_complete)) {
      parameters.range_complete = newDateString
    }

    const analysed = await calculateDeviationsParams({
      trendline: parameters,
      multiple: true,
      sliced: false,
      recalculateParams: true,
      frequency: parameters.frequency || 'monthly',
      baseIndicatorTitle: parameters.title || '',
      baseIndicatorsFid: indicatorId
    })

    const { merged, ...rest } = analysed

    setData(merged)
    setCalculatedParams(rest)
  }

  const handleComplete = async (preview: boolean) => {
    const analysedTrendlineData = calculatedParams?.trendline
    if (!analysedTrendlineData) return false

    const parameters = {
      range_complete: analysedTrendlineData.range_complete,
      range_chosen: analysedTrendlineData.range_chosen,
      range_chosen_deviation: analysedTrendlineData.range_chosen_deviation,
      standard_deviation_parameters:
        analysedTrendlineData.standard_deviation_parameters,
      standard_deviation: analysedTrendlineData.standard_deviation,
      standard_deviation_relative:
        analysedTrendlineData.standard_deviation_relative,
      standard_deviation_chart_mode: computationMode,
      meaning: fetchedIndicatorParameters.meaning,
      short_description: fetchedIndicatorParameters.short_description,
      tags: [],
      type: fetchedIndicatorParameters.type,
      frequency: findFrequencyFromTheName(analysedTrendlineData.frequency),
      data_mode: analysedTrendlineData.data_mode,
      inverse: analysedTrendlineData.inverse,
      title: `${fetchedIndicatorParameters.title} Trendline`
    }

    if (preview) {
      const transformedRange =
        transformFetchedRangedDetailsScenariotoRangeInputs(
          analysedTrendlineData.range_chosen_deviation
        )

      handleCalculateTrendlineParameters(parameters)

      setSelectedDataBoundaries(transformedRange)
      return true
    } else {
      const result = await createNewTrendline({
        indicatorFid: indicatorId,
        sourceInfo: {
          source,
          source_fid
        },
        params: parameters
      })

      if (result) {
        window.switchFunctions.trendline(result)
        return true
      }
      return false
    }
  }

  useEffect(() => {
    handleCalculateTrendlineParameters(fetchedIndicatorParameters as ITrendline)
  }, [])

  if (data === undefined) {
    return <Spinner />
  }

  return (
    <div className="entity-blocks">
      <div className="container w-12 no-scrollbar no-borders">
        <div className="flex-column">
          <div className="flex gap-2 middle m-0 p-2 col-12 space-between">
            <RangeSlider2
              dataArray={fetchedDataTransformed.dates}
              initialDetails={transformFetchedRangedDetailsScenariotoRangeInputs(
                fetchedIndicatorParameters?.range_chosen_deviation
              )}
              selectedDetails={selectedDataBoundaries}
              dateSliderMode={true}
              iconMode={'calculate'}
              customStyles={{
                width: 400,
                padding: '6px 20px 6px 5px'
              }}
              noSlider={true}
              noApplyButton={true}
              onApply={() => undefined}
              handleChange={(value: string[]) => {
                const newRange = value.map((item) => item.split('T')[0])
                const newRangeForDb = `[${newRange[0]},${newRange[1]})`
                setCalculatedParams(
                  (prev) =>
                    ({
                      ...prev,
                      trendline: {
                        ...prev.trendline,
                        range_chosen_deviation: newRangeForDb
                      }
                    }) as ICalculateDeviationsParamsReturnParams
                )
              }}
            />
          </div>

          <div className="flex gap-2 middle m-0 p-2 col-12">
            <label>Method:</label>
            <select
              value={computationMode || 'exponential'}
              onChange={(e) => setComputationMode(e.target.value)}
            >
              <option value="exponential">Exponential</option>
              <option value="linear">Linear</option>
              {/* <option value="cagr">CAGR</option> */}
              {/* <option value="average">Moving Average</option> */}
            </select>
          </div>

          <div className="flex col-12 margin-1 ">
            <Checkbox
              checked={calculatedParams.trendline.standard_deviation_relative}
              onChange={() => {
                setCalculatedParams((prev) => ({
                  ...prev,
                  trendline: {
                    ...prev.trendline,
                    standard_deviation_relative:
                      !prev.trendline.standard_deviation_relative
                  }
                }))
              }}
              color="success-o"
              animation="pulse"
              shape="curve"
              disabled={false}
            >
              Relative
            </Checkbox>
            <Checkbox
              checked={calculatedParams.trendline.inverse}
              onChange={() => {
                setCalculatedParams((prev) => ({
                  ...prev,
                  trendline: {
                    ...prev.trendline,
                    inverse: !prev.trendline.inverse
                  }
                }))
              }}
              color="success-o"
              animation="pulse"
              shape="curve"
              disabled={false}
            >
              Inverse colours
            </Checkbox>
          </div>
        </div>

        <div className="flex col-12 p-2 align-items-start center gap-3">
          <FunctionalButton
            className="teriary wide"
            initialButtonState={'Discard'}
            iconDelete
            combinedButtonTitle
            functionToExecute={closeModal}
            doesReset
          />
          <FunctionalButton
            className="secondary wide"
            initialButtonState={'Preview'}
            functionToExecute={() => handleComplete(true)}
            doesReset
          />
          <FunctionalButton
            className="primary wide"
            initialButtonState={'Submit'}
            combinedButtonTitle
            iconSaveMode
            functionToExecute={() => handleComplete(false)}
            doesReset
          />
        </div>
      </div>
      <div className="container w-6 auto no-scrollbar">
        <div className="entity-chart">
          <HighChartsLinearChart
            chartOptions={{
              xAxisTitle: 'Month',
              yAxisTitle: 'Value',
              title: 'New Trendline Chart',
              reactive: true,
              mediumSize: true,
              oneAxis: true
            }}
            chartData={{
              fetchedData: data || [],
              fullDates: fetchedDataTransformed.dates,
              titles: [{ title: '' }],
              filteredKeys: [
                calculatedParams.keys.valuesTitle,
                calculatedParams.keys.trendlineTitle
              ]
            }}
          />
        </div>
      </div>
      <div className="container w-6 auto no-scrollbar">
        <div className="entity-chart">
          <HighChartsLinearChart
            chartOptions={{
              xAxisTitle: 'Month',
              yAxisTitle: 'Value',
              title: 'Residuals Chart',
              reactive: true,
              mediumSize: true
            }}
            chartData={{
              fetchedData: data || [],
              fullDates: fetchedDataTransformed.dates,
              titles: [{ title: '' }],
              filteredKeys: [
                calculatedParams.keys.residualOverDevTitle,
                calculatedParams.keys.residualTitle
              ]
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default CreateTrendline
