import React from 'react'
import { CollapsibleSidePanelProps } from '../utils/interfaces'
import { FunctionalButton } from './_components'
import { IconClose } from './Icons'

const CollapsibleSidePanel: React.FC<CollapsibleSidePanelProps> = ({
  isOpen,
  onToggle,
  fullScreen,
  functionalButton,
  children,
  position = 'right'
}) => {
  return (
    <div
      className={`collapsible-side-panel ${isOpen ? 'open' : ''} ${position}${fullScreen ? ' full-screen' : ''}`}
    >
      <div className="panel-content">
        <button
          onClick={onToggle}
          className="no-btn no-hover icon t-large"
          style={{
            position: 'absolute',
            right: '5px',
            top: '5px',
            zIndex: 100,
            scale: '2'
          }}
        >
          <IconClose />
        </button>
        {children}
        <div className="flex-row col-12 space-evenly">
          {functionalButton && (
            <FunctionalButton
              initialButtonState={functionalButton.initialButtonState}
              iconSaveMode={functionalButton.iconSaveMode}
              combinedButtonTitle={functionalButton.combinedButtonTitle}
              disabled={functionalButton.disabled}
              functionToExecute={functionalButton.functionToExecute}
              className={'primary col-5'}
              refreshOnComplete={functionalButton.refreshOnComplete}
              doesReset={functionalButton.doesReset}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default CollapsibleSidePanel
