import { CALCULATED_TYPES } from './_constants'
import { createInteractionRecord } from './fetch'
import {
  IBasicIndicator,
  ICalculatedIndicator,
  IEntityAllKeys,
  IEquationPiece
} from './interfaces'
import {
  findClosestMidnightString,
  findClosestMidnight
} from './transformingData'

const isEmpty = (cell: string | null | undefined | number) => {
  if (
    cell === null ||
    cell === undefined ||
    cell === '' ||
    cell === 'null' ||
    cell === 'undefined' ||
    cell === ' ' ||
    cell === 'NaN' ||
    cell === 'NaN%' ||
    cell === ', ' ||
    cell === 0 ||
    cell === Infinity ||
    cell === -Infinity
  ) {
    return true
  } else {
    return false
  }
}
function generateRandomId() {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < 8; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const transformFullDateToMonthAndYear = (date: string | Date) => {
  if (typeof date === 'string' && isEmpty(date)) return date
  const converted = findClosestMidnightString(date)
  const shortYear = converted.slice(2, 4)
  const month = converted.slice(5, 7)
  const day = converted.slice(8, 10)
  return `${day}/${month}/${shortYear}`
}

const transformFullDateToYearMonthDay = (date: string) => {
  if (isEmpty(date)) return date

  const converted = findClosestMidnightString(date)
  const year = converted.slice(0, 4)
  const month = converted.slice(5, 7)
  const day = converted.slice(8, 10)
  return `${year}/${month}/${day}`
}

const capitalise = (str: string | undefined) =>
  str === undefined ? '' : str.charAt(0).toUpperCase() + str.slice(1)

const isLoggedIn = () => {
  const token = localStorage.getItem('token')
  // const userId = localStorage.getItem('userId')
  if (
    token === 'undefined' ||
    token === null ||
    token === '' ||
    !token ||
    token === 'null' ||
    token === undefined ||
    token === 'false'
  ) {
    return false
  }
  return true
}

function findHighestValue(arr: (string | number)[]) {
  if (arr.length === 0) {
    return 0
  }
  const numberArr = arr.map(Number)
  const sortedArr = numberArr.sort((a, b) => a - b)
  return sortedArr[sortedArr.length - 1]
}

const getPreviousPage = (deleted?: boolean) => {
  if (deleted) {
    window.visitedPages = window.visitedPages.slice(0, -1)
  }

  const page = window.visitedPages[window.currentPageIndex - 2]
  if (!page) {
    return null
  }

  return page
}

const getNextPage = () => {
  const page = window.visitedPages[window.currentPageIndex]

  if (!page) {
    return null
  }

  return page
}

const getCurrentPage = () => {
  return window.visitedPages[window.currentPageIndex - 1]
}

const addToVisitedPages = ({
  type,
  fid,
  pageMode
}: {
  type: string
  fid: string | null | number
  pageMode?: string
}) => {
  const currentPage = getCurrentPage()
  if (currentPage && currentPage.fid === fid && currentPage.type === type) {
    if (isEmpty(pageMode)) {
      return
    } else if (pageMode === currentPage.pageMode) {
      return
    }
  }

  const currentIndex = window.currentPageIndex
  const visitedPages = window.visitedPages
  if (visitedPages.length === currentIndex) {
    window.currentPageIndex += 1
    window.visitedPages = [
      ...visitedPages,
      {
        type,
        fid,
        pageMode
      }
    ]
  } else {
    const newVisitedPages = [
      ...visitedPages.slice(0, currentIndex),
      {
        type,
        fid,
        pageMode
      }
    ]
    window.visitedPages = newVisitedPages
    window.currentPageIndex = newVisitedPages.length
  }
}

const updateLocalStoragePageHistory = ({
  type,
  fid
}: {
  type: string
  fid: string | null | number
}) => {
  createInteractionRecord(type, fid)
  addToVisitedPages({
    type,
    fid
  })
}

const transformFetchedRangedDetailsScenariotoRangeInputs = (
  details: string
) => {
  // example [1992-01-01,2023-10-02)
  if (!details) return ['', '']
  const detailsArray = details.split(',')
  const firstDate = detailsArray[0].slice(1)
  const secondDate = detailsArray[1].slice(0, -1)
  return [firstDate, secondDate]
}

const transformFetchedRangedDetailsScenariotoRangeInputsSlash = (
  details: string
) => {
  // example [1992-01-01,2023-10-02)
  if (!details) return ['', '']
  const detailsArray = details.replace(/-/g, '/').split(',')
  const firstDate = detailsArray[0].slice(1)
  const secondDate = detailsArray[1].slice(0, -1)
  return [firstDate, secondDate]
}

const findFrequencyFromTheName = (name: string) => {
  if (!name) return 'monthly'
  if (name.toLowerCase().includes('daily')) {
    return 'daily'
  } else if (name.toLowerCase().includes('weekly')) {
    return 'weekly'
  } else if (name.toLowerCase().includes('monthly')) {
    return 'monthly'
  } else if (name.toLowerCase().includes('quarterly')) {
    return 'quarterly'
  } else if (name.toLowerCase().includes('yearly')) {
    return 'yearly'
  }

  return 'monthly'
}

const filterOutDevFromName = (
  name: string | null | undefined,
  replacement = ''
) => {
  // I am the worst fucking hack in this world LOL BUT
  // The Big Boss want the data tables and charts to display exact same titles
  // and I can't be fucked to change the whole logic
  // just to accomodate that shit

  // ... I agree the logic is bad, but it became a kind of a snowman
  // and I will be remaking the Data table anyway, so
  // hopefully this will become obsolete then

  if (isEmpty(name)) return replacement

  if (name?.trim() === ' Deviation') {
    return 'Deviations'
  }

  return (name as string).replace(' Deviation', replacement)
}

function compareDates(dateStr1: string | Date, dateStr2: string | Date) {
  const date1 = findClosestMidnight(dateStr1)
  const date2 = findClosestMidnight(dateStr2)

  if (date1.getUTCFullYear() !== date2.getUTCFullYear()) {
    return false
  }

  // if the dates are separated by 3 or less days, return true
  const diffTime = Math.abs(date2.getTime() - date1.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  if (diffDays <= 3) {
    return true
  } else {
    return false
  }
}

const saveDataToCache = (key: string, data: unknown, date?: Date): boolean => {
  if (!date) {
    date = new Date()
  }
  try {
    const object = {
      data,
      date: date.toISOString()
    }

    const stringified = JSON.stringify(object)

    // Save the serialized data to localStorage
    localStorage.setItem(key, stringified)

    return true
  } catch (error) {
    console.error('Failed to save data to cache:', error)
    return false
  }
}

const retrieveFromCache = (
  key: string,
  expiry: number = 1000 * 60 * 60 * 24
): {
  data: unknown
  date: string
} => {
  try {
    // Retrieve the serialized data from localStorage
    const item = localStorage.getItem(key)
    if (item === null) {
      return {
        data: null,
        date: ''
      }
    }

    const object = JSON.parse(item)
    const date = new Date(object.date)
    const now = new Date()
    const diffTime = Math.abs(now.getTime() - date.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays > expiry) {
      return {
        data: null,
        date: ''
      }
    }

    return object as { data: unknown; date: string }
  } catch (error) {
    console.error('Failed to retrieve data from cache:', error)
    return {
      data: null,
      date: ''
    }
  }
}

const getFilteredKeys = ({
  keys,
  mode,
  backupFids,
  bannedFids
}: {
  keys: IEntityAllKeys[] | undefined
  mode: 'values' | 'deviations' | 'both'
  backupFids?: string[]
  bannedFids?: string[]
}) => {
  let result = []
  const arrayToUse =
    keys !== undefined ? keys.map((item) => item.title) : backupFids || []
  if (mode === 'deviations') {
    result = arrayToUse.map((item) => [item, item + ' Deviation'])
  } else if (mode === 'values') {
    result = arrayToUse.map((item) => [item, item + ' Value'])
  } else {
    result = arrayToUse.map((item) => [
      item,
      item + ' Value',
      item + ' Deviation'
    ])
  }

  return result.flat().filter((item) => !bannedFids?.includes(item))
}

const findMatchPercentage = (arr1: string[], arr2: string[]) => {
  const intersection = arr1.filter((value) => arr2.includes(value))
  return (intersection.length / arr1.length) * 100
}

const printPDF = async (pdfObject: any) => {
  try {
    // create a pdf from the result
    const pdfBlob = await fetch(pdfObject.pdfData).then((res) => res.blob())
    const pdfFile = new File([pdfBlob], pdfObject.filename, {
      type: 'application/pdf'
    })

    // save the pdf to the device
    const pdfUrl = URL.createObjectURL(pdfFile)
    const downloadLink = document.createElement('a')
    downloadLink.href = pdfUrl
    downloadLink.download = pdfObject.filename
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    return true
  } catch (error) {
    console.error('Error printing PDF:', error)
    return false
  }
}

const generateTitlesForFidsInAString = (
  str: string,
  indicatorsParameters: IBasicIndicator[]
) => {
  const indicatorsAll = indicatorsParameters.map((ind) => {
    return {
      name: ind.fid,
      title: ind.title
    }
  })
  const indicatorsIncludedInString = indicatorsAll.filter((ind) =>
    str.includes(ind.name)
  )

  return indicatorsIncludedInString
}

const checkEquationValidity = (
  equation: IEquationPiece[],
  indicatorsNames: string[],
  setError: (error: string) => void,
  OPERATIONS: string[]
) => {
  if (equation.length === 0) {
    setError('Equation is empty')
    return false
  }

  // ensure thatno pieces of the same type are next to each other unless they are operations
  for (let i = 0; i < equation.length - 1; i++) {
    if (
      equation[i].type === equation[i + 1].type &&
      !OPERATIONS.includes(equation[i].value)
    ) {
      setError(
        'Equation should not have two parts of the same type next to each other'
      )
      return false
    }
  }

  // ensure that the equation starts with an indicator, number or an operator opening bracket
  if (
    !['indicator', 'number', 'external'].includes(equation[0].type) &&
    equation[0].value !== '('
  ) {
    setError(
      'Equation should start with an indicator, number or an opening bracket'
    )
    return false
  }

  // ensure that the equation ends with an indicator, number or an operator closing bracket
  if (
    !['indicator', 'number', 'external'].includes(
      equation[equation.length - 1].type
    ) &&
    equation[equation.length - 1].value !== ')'
  ) {
    setError(
      'Equation should end with an indicator, number or a closing bracket'
    )
    return false
  }

  // ensure that the equation has at least one indicator
  if (
    !equation.some(
      (piece) => piece.type === 'indicator' || piece.type === 'external'
    )
  ) {
    setError('Equation should have at least one indicator')
    return false
  }

  // ensure that the equation has at least one operation
  if (!equation.some((piece) => OPERATIONS.includes(piece.value))) {
    setError('Equation should have at least one operation')
    return false
  }

  // ensure that all indicators in the equation are valid and part of the indicatorsNames
  const invalidIndicators = equation.filter(
    (piece) =>
      (piece.type === 'indicator' || piece.type === 'external') &&
      !indicatorsNames.includes(piece.value)
  )
  if (invalidIndicators.length > 0) {
    setError(
      `Equation has invalid indicators: ${invalidIndicators
        .map((ind) => ind.value)
        .join(', ')}`
    )
    return false
  }

  return true
}

const getTypeOfCalculated = (parameters: ICalculatedIndicator) => {
  let mode = 'calculated'
  if (parameters.data_mode === 'deviations') {
    mode = 'deviations'
  } else if (parameters.data_mode === 'values') {
    mode = parameters.computation_mode

    if (mode.includes('offset')) {
      mode = 'offset'
    }

    if (mode.includes('---')) {
      mode = mode.split('---')[0]
    }
  }

  return CALCULATED_TYPES[mode as keyof typeof CALCULATED_TYPES]
}

function debounce<T extends any[]>(
  func: (...args: T) => void,
  wait: number
): (...args: T) => void {
  let timeout: any = null

  return function executedFunction(...args: T) {
    const later = () => {
      if (timeout !== null) {
        clearTimeout(timeout)
      }
      func(...args)
    }

    if (timeout !== null) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(later, wait)
  }
}

// eslint-disable-next-line no-unused-vars
const evaluateLocalStoragePageMode = (currentIndicator: string) => {
  const pageMode = localStorage.getItem('indicatorPageMode')
  if (pageMode && pageMode.includes('---')) {
    const parts = pageMode.split('---')
    if (parts[1] === currentIndicator) {
      return parts[0] as 'basic' | 'trendline' | 'forecast'
    }
  }

  return 'basic' as 'basic' | 'trendline' | 'forecast'
}

const generateShades = (hex: string, shades: number, variation: number) => {
  const base = parseInt(hex.slice(1), 16)
  const red = (base >> 16) & 0xff
  const green = (base >> 8) & 0xff
  const blue = base & 0xff

  const threshold = 50 // Minimum value to prevent color from becoming black
  const colors = []

  for (let i = 0; i < shades; i++) {
    const randomiser = Math.random() * 2 - 1.5
    const changeChannel = Math.floor(Math.random() * 3)

    let newRed = red
    let newGreen = green
    let newBlue = blue

    if (changeChannel === 0) {
      newRed = Math.max(threshold, Math.floor(red + i * variation * randomiser))
    } else if (changeChannel === 1) {
      newGreen = Math.max(
        threshold,
        Math.floor(green + i * variation * randomiser)
      )
    } else {
      newBlue = Math.max(
        threshold,
        Math.floor(blue + i * variation * randomiser)
      )
    }

    colors.push(
      `#${((1 << 24) + (newRed << 16) + (newGreen << 8) + newBlue).toString(16).slice(1)}`
    )
  }

  return colors
}

export {
  isLoggedIn,
  capitalise,
  updateLocalStoragePageHistory,
  addToVisitedPages,
  getPreviousPage,
  getNextPage,
  findHighestValue,
  isEmpty,
  debounce,
  transformFetchedRangedDetailsScenariotoRangeInputs,
  transformFetchedRangedDetailsScenariotoRangeInputsSlash,
  printPDF,
  transformFullDateToMonthAndYear,
  getCurrentPage,
  compareDates,
  generateRandomId,
  getTypeOfCalculated,
  generateTitlesForFidsInAString,
  getFilteredKeys,
  checkEquationValidity,
  filterOutDevFromName,
  saveDataToCache,
  generateShades,
  retrieveFromCache,
  findMatchPercentage,
  findFrequencyFromTheName,
  evaluateLocalStoragePageMode,
  transformFullDateToYearMonthDay
}
