import {
  IGoogleChartWithRangeFilterData,
  IOverlaysInputsToBeTransformedIntoZones,
  IPercentageDIfferences,
  IMappedDataEntry,
  ITransformedOverlayZonePareters,
  ITransformedTick,
  IEntityAllKeys,
  ITrendline,
  IUserSettings,
  IResidualWithDate,
  IDataMapped,
  ICollection,
  IRecordedInteraction,
  IQuickSearchEntity,
  IQuickSearchEntityWithDate,
  ICollectionEntry,
  EntitiesInput,
  IBasicIndicator,
  IEquationPiece,
  DataSeries,
  IFetchedDataEntry,
  ICombinedIndicatorFormulaAlternativeEquationBlocks
} from './interfaces'
import * as XLSX from 'xlsx'
import { compareDates, isEmpty } from './functions'
import { fetchChosenIndicator } from './fetch'
import { findMinChartIndex } from '../charts/_googleChartFunctions'
import { Frequency } from '../charts/interfaces'

const convertExcelDateToHTMLDate = (inputDate: string | number | null) => {
  if (!inputDate) return ''
  const date = new Date(inputDate)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  return `${year}-${day}-${month}`
}

const getExistingColumns = (params: IBasicIndicator) =>
  [
    !isEmpty(params.chart_columns_selected)
      ? params.chart_columns_selected.split(', ')
      : [],
    !isEmpty(params.columns_selected) ? params.columns_selected.split(', ') : []
  ]
    .flat()
    .filter((item) => !isEmpty(item))
    .filter((item, index, self) => self.indexOf(item) === index)

const generateRandomNumber = (min: number, max: number) =>
  Math.random() * (max - min) + min

// const extractDataFromDate = (inputDate: Date | string) => {
//   try {
//     if (!inputDate) {
//       return {
//         day: 1,
//         month: 0,
//         year: 1970
//       }
//     }
//     let date = typeof inputDate === 'string' ? new Date(inputDate) : inputDate

//     if (isNaN(date.getTime())) {
//       date = new Date()
//     }

//     const day = date.getDate()
//     let month = date.getMonth() // Keep month 0-indexed internally for JavaScript Date calculations
//     let year = date.getFullYear()

//     // If the day is more than 5, we move to the next month
//     if (day > 25) {
//       month += 1 // Move to next month
//       // Check for year rollover
//       if (month > 11) {
//         // December is 11 in 0-indexed months
//         month = 0 // January (of next year)
//         year += 1
//       }
//     }

//     return {
//       day,
//       month,
//       year
//     }
//   } catch (error) {
//     console.error('Error in extractDataFromDate', inputDate)
//     return {
//       day: 1,
//       month: 0,
//       year: 1970
//     }
//   }
// }

// Helper function to find the closest midnight
const findClosestMidnight = (dateString: string | Date) => {
  const date = new Date(dateString)

  // Midnight of the same day
  const sameDayMidnight = new Date(date)
  sameDayMidnight.setUTCHours(0, 0, 0, 0)

  // Midnight of the next day
  const nextDayMidnight = new Date(date)
  nextDayMidnight.setUTCDate(nextDayMidnight.getUTCDate() + 1)
  nextDayMidnight.setUTCHours(0, 0, 0, 0)

  // Calculate the differences in milliseconds
  const sameDayDifference = Math.abs(date.getTime() - sameDayMidnight.getTime())
  const nextDayDifference = Math.abs(date.getTime() - nextDayMidnight.getTime())

  // Return the closest midnight
  return sameDayDifference <= nextDayDifference
    ? sameDayMidnight
    : nextDayMidnight
}

// Helper function to find the closest midnight
const findClosestMidnightString = (dateString: string | Date) =>
  findClosestMidnight(dateString).toISOString().split('T')[0]

const transformSettings = (settings: any): IUserSettings => {
  const processedSettings = {
    ...settings,
    data_mapped_all_columns: isEmpty(settings.data_mapped_all_columns)
      ? []
      : settings.data_mapped_all_columns
          .split(', ')
          .filter((el: any) => el !== ''),
    data_mapped_selected_columns: isEmpty(settings.data_mapped_selected_columns)
      ? [].filter((el: any) => el !== '')
      : settings.data_mapped_selected_columns
          .split(', ')
          .filter((el: any) => el !== ''),
    boundaries_in_sd: isEmpty(settings.boundaries_in_sd)
      ? { 1: -2, 2: -1, 3: 0, 4: 1, 5: 2 }
      : JSON.parse(settings.boundaries_in_sd),
    colours_data_mapped: isEmpty(settings.colours_data_mapped)
      ? {
          1: '#b64a4a',
          2: '#eeaabe',
          3: '#f2bf8c',
          4: '#fffccc',
          5: '#b1df9f',
          6: '#519238'
        }
      : JSON.parse(settings.colours_data_mapped),
    rises_and_drops_in_sd: isEmpty(settings.rises_and_drops_in_sd)
      ? { 1: -0.6, 2: -0.3, 3: 0.3, 4: 0.6, state: 'on' }
      : JSON.parse(settings.rises_and_drops_in_sd),
    beta: process.env.NODE_ENV === 'development' || settings.beta,
    homepage_trendlines: isEmpty(settings.homepage_trendlines)
      ? []
      : typeof settings.homepage_trendlines === 'string'
        ? JSON.parse(settings.homepage_trendlines)
        : settings.homepage_trendlines,
    homepage_memos:
      !isEmpty(settings.homepage_memos) &&
      typeof settings.homepage_memos === 'string'
        ? settings.homepage_memos.split(', ').filter((el: any) => el !== '')
        : [],
    homepage_scenarios:
      !isEmpty(settings.homepage_scenarios) &&
      typeof settings.homepage_scenarios === 'string'
        ? settings.homepage_scenarios.split(', ').filter((el: any) => el !== '')
        : []
  }

  const language = processedSettings.language
  document.documentElement.lang = language

  return processedSettings
}

const transformAndSortSidePanelEntities = (
  favourites: ICollection,
  timeseriesData: IRecordedInteraction[]
): IQuickSearchEntity[] => {
  try {
    const {
      scenarios: favouriteScenarios = [],
      indicators: favouriteIndicators = [],
      collections: favouriteCollections = [],
      memos: favouriteMemos = [],
      calculated_indicators: favouriteCalculatedIndicators = [],
      trendlines: favouriteTrendlines = [],
      forecasts: favouriteForecasts = [],
      externals: favouriteExternals = []
    } = favourites

    // because these are from favourites, they are not is_own by default

    const unsorted: IQuickSearchEntity[] = [
      ...favouriteScenarios.map((scenario: ICollectionEntry) => ({
        id: scenario.id,
        name: scenario.name,
        type: 'Scenario',
        functionToExecute: {
          title: 'Open',
          function: () => {
            window.switchFunctions.scenario(scenario.id)
            return true
          }
        },
        is_own: false
      })),
      ...favouriteIndicators.map((indicator: ICollectionEntry) => ({
        id: indicator.id,
        name: indicator.name,
        type: 'Indicator',
        functionToExecute: {
          title: 'Open',
          function: () => {
            window.switchFunctions.indicator(indicator.id)
            return true
          }
        },
        is_own: false
      })),
      ...favouriteCalculatedIndicators.map(
        (calculatedIndicator: ICollectionEntry) => ({
          id: calculatedIndicator.id,
          name: calculatedIndicator.name,
          type: 'Calculated',
          functionToExecute: {
            title: 'Open',
            function: () => {
              window.switchFunctions.calculated(calculatedIndicator.id)
              return true
            }
          },
          is_own: false
        })
      ),
      ...favouriteTrendlines.map((trendline: ICollectionEntry) => ({
        id: trendline.id,
        name: trendline.name,
        type: 'Trendline',
        functionToExecute: {
          title: 'Open',
          function: () => {
            window.switchFunctions.trendline(trendline.id)
            return true
          }
        },
        is_own: false
      })),
      ...favouriteMemos.map((memo: { id: any; name: any }) => ({
        id: memo.id,
        name: memo.name,
        type: 'Memo',
        functionToExecute: {
          title: 'Open',
          function: () => {
            window.switchFunctions.memo(memo.id)
            return true
          }
        },
        is_own: false
      })),
      ...favouriteCollections.map((collection: { id: any; name: any }) => ({
        id: collection.id,
        name: collection.name,
        type: 'Collection',
        functionToExecute: {
          title: 'Open',
          function: () => {
            window.switchFunctions.collection(collection.id)
            return true
          }
        },
        is_own: false
      })),
      ...favouriteForecasts.map((forecast: { id: any; name: any }) => ({
        id: forecast.id,
        name: forecast.name,
        type: 'Forecast',
        functionToExecute: {
          title: 'Open',
          function: () => {
            window.switchFunctions.forecast(forecast.id)
            return true
          }
        },
        is_own: false
      })),
      ...favouriteExternals.map((external: { id: any; name: any }) => ({
        id: external.id,
        name: external.name,
        type: 'External',
        functionToExecute: {
          title: 'Open',
          function: () => {
            window.switchFunctions.external(external.id)
            return true
          }
        },
        is_own: false
      }))
    ]

    if (!timeseriesData) return unsorted

    const dataWithDates: IQuickSearchEntityWithDate[] = unsorted.map((item) => {
      const newItem = {
        ...item,
        visited_at: new Date(0)
      }
      const itemData = timeseriesData.find(
        (data) => data.entity_fid === item.id
      )
      if (itemData) {
        newItem.visited_at = new Date(itemData.visited_at)
      }

      return newItem
    })

    // Sorting function by visited_at in descending order
    const sorted = dataWithDates
      .sort((a, b) => a.visited_at.getTime() - b.visited_at.getTime())
      .reverse()

    return sorted
  } catch (error) {
    console.warn("Couldn't transform the data", error)
    return []
  }
}

const transformAndSortAllEntities = (
  input: EntitiesInput
): IQuickSearchEntity[] => {
  try {
    const {
      scenariosList = [],
      indicators = [],
      collections = [],
      memos = [],
      trendlines = [],
      forecasts = [],
      records = null
    } = input

    const transformedScenarios = scenariosList.map((item) => ({
      id: item.fid,
      name: item.scenario_name,
      type: 'Scenario',
      functionToExecute: {
        title: 'Open',
        function: () => {
          window.switchFunctions.scenario(item.fid)
          return true
        }
      },
      is_own: item.is_own
    }))

    const transformedIndicators = indicators
      .filter((item) => item.type === 'indicator' || item.type === 'actuals')
      .map((item) => ({
        id: item.fid,
        name: item.title,
        type: 'Indicator',
        functionToExecute: {
          title: 'Open',
          function: () => {
            window.switchFunctions.indicator(item.fid)
            return true
          }
        },
        is_own: item.is_own
      }))

    const transformedCalculatedIndicators = indicators
      .filter((item) => item.type === 'calculated')
      .map((item) => ({
        id: item.fid,
        name: item.title,
        type: 'Calculated',
        functionToExecute: {
          title: 'Open',
          function: () => {
            window.switchFunctions.calculated(item.fid)
            return true
          }
        },
        is_own: item.is_own
      }))

    const combinedTransformedIndicators = [
      ...transformedIndicators,
      ...transformedCalculatedIndicators
    ]

    const transformedCollections = collections.map((item) => ({
      id: item.id,
      name: item.collection_name,
      type: 'Collection',
      functionToExecute: {
        title: 'Open',
        function: () => {
          window.switchFunctions.collection(item.id)
          return true
        }
      },
      is_own: item.is_own
    }))

    const transformedMemos = memos.map((item) => ({
      id: item.fid,
      name: item.title,
      type: 'Memo',
      functionToExecute: {
        title: 'Open',
        function: () => {
          window.switchFunctions.memo(item.fid)
          return true
        }
      },
      is_own: item.is_own
    }))

    const transformedTrendlines = trendlines.map((item) => ({
      id: item.fid,
      name: item.title,
      type: 'Trendline',
      functionToExecute: {
        title: 'Open',
        function: () => {
          window.switchFunctions.trendline(item.fid)
          return true
        }
      },
      is_own: item.is_own
    }))

    const transformedForecasts = forecasts.map((item) => ({
      id: item.fid,
      name: item.title,
      type: 'Forecast',
      functionToExecute: {
        title: 'Open',
        function: () => {
          window.switchFunctions.forecast(item.fid)
          return true
        }
      },
      is_own: item.is_own
    }))

    const transformedEntities: IQuickSearchEntity[] = [
      ...transformedScenarios,
      ...combinedTransformedIndicators,
      ...transformedCollections,
      ...transformedMemos,
      ...transformedTrendlines,
      ...transformedForecasts
    ]

    if (!records) {
      return transformedEntities
    }

    const transformedEntitiesWithDates: IQuickSearchEntityWithDate[] =
      transformedEntities.map((entity) => {
        const entityData = records.find((data) => data.entity_fid === entity.id)
        return {
          ...entity,
          visited_at: entityData ? new Date(entityData.visited_at) : new Date(0)
        }
      })

    return transformedEntitiesWithDates
  } catch (error) {
    console.warn("Couldn't transform the data and sort it", error)
    return []
  }
}

const mergeValuesAndTrendlinesByDate = (
  arr1: { date: string; [key: string]: any }[],
  arrayOfArrs: { date: string; [key: string]: any }[][]
) => {
  // this is specifically for hmepage due to the way the data is structured

  // Flatten the array of arrays
  const arr2 = arrayOfArrs.flat().filter((item) => item !== null)
  const normalizeDate = (date: string | Date) =>
    new Date(date).toISOString().split('T')[0]

  if (arr2.length === 0) return arr1.filter((item) => item !== null)
  if (arr1.length === 0) return arr2.filter((item) => item !== null)

  // Proceed with merging
  return arr1
    .filter((item) => item !== null)
    .map((item1) => {
      // Find all matching date entries in arr2
      const date1 = normalizeDate(item1.date)
      // Find all matching date entries in arr2
      const matches = arr2.filter((item2) => {
        const date2 = normalizeDate(item2.date)
        return date1 === date2
      })

      let mergedItem = { ...item1 }
      // rewrite the Trendline Values key to be the same as the indicator key
      const itemKeys = Object.keys(item1).filter(
        (key) => key !== 'date' && key !== 'id' && key !== 'Trendline Values'
      )

      mergedItem['Deviation ' + itemKeys[0]] = item1['Trendline Values']
      delete mergedItem['Trendline Values']

      if (matches.length > 0) {
        // Merge all matching items with item1
        matches.forEach((match) => {
          const { date, ...rest } = match
          const valueKey = Object.keys(rest)[0]
          const value = rest[valueKey]
          const deviationKey = 'Deviation ' + valueKey
          mergedItem = {
            ...mergedItem,
            [deviationKey]: rest['Trendline Values'],
            [Object.keys(rest)[0]]: value
          }
          delete mergedItem['Trendline Values']
        })

        return mergedItem
      }

      // If no matches found, return item1
      return item1
    })
}

const mergeByDate = (
  arr1: { date: string | Date; [key: string]: any }[],
  arrayOfArrs: { date: string | Date; [key: string]: any }[][]
) => {
  try {
    // if either is undefined, return the other
    if (!arr1 && arrayOfArrs) {
      return arrayOfArrs.flat().filter((item) => item !== null)
    }
    if (!arrayOfArrs && arr1) return arr1.filter((item) => item !== null)
    if (!arr1 && !arrayOfArrs) return []

    // Flatten the array of arrays
    const arr2 = arrayOfArrs.flat().filter((item) => item !== null)

    if (arr2.length === 0) {
      return arr1.filter((item) => item !== null)
    }
    if (arr1.length === 0) {
      return arr2.filter((item) => item !== null)
    }

    // Helper function to find the closest midnight
    const findClosestMidnight = (dateString: string | Date) => {
      const date = new Date(dateString)

      // Midnight of the same day
      const sameDayMidnight = new Date(date)
      sameDayMidnight.setUTCHours(0, 0, 0, 0)

      // Midnight of the next day
      const nextDayMidnight = new Date(date)
      nextDayMidnight.setUTCDate(nextDayMidnight.getUTCDate() + 1)
      nextDayMidnight.setUTCHours(0, 0, 0, 0)

      // Calculate the differences in milliseconds
      const sameDayDifference = Math.abs(
        date.getTime() - sameDayMidnight.getTime()
      )
      const nextDayDifference = Math.abs(
        date.getTime() - nextDayMidnight.getTime()
      )

      // Return the closest midnight
      return sameDayDifference <= nextDayDifference
        ? sameDayMidnight.toISOString()
        : nextDayMidnight.toISOString()
    }

    // Proceed with merging
    return arr1
      .filter((item) => item !== null)
      .map((item1) => {
        // Find all matching date entries in arr2
        const date1 = findClosestMidnight(item1.date)
        // Find all matching date entries in arr2
        const matches = arr2.filter((item2) => {
          const date2 = findClosestMidnight(item2.date)
          return date1 === date2
        })

        if (matches.length > 0) {
          // Merge all matching items with item1
          let mergedItem = { ...item1 }
          matches.forEach((match) => {
            const { date, ...rest } = match
            mergedItem = { ...mergedItem, ...rest }
          })
          return mergedItem
        }

        // If no matches found, return item1
        return item1
      })
  } catch (error) {
    console.warn(error)
    return []
  }
}

const orderRowsByDates = (rows: any[]) => {
  return rows.sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime()
  })
}

const returnMergedByDate = (
  arr1: any[] | undefined,
  arrayOfArrs: any[][],
  enforceArr1?: boolean
) => {
  try {
    if (!enforceArr1 && arr1 && arrayOfArrs.length === 0) {
      if (!arr1 && !arrayOfArrs) return []
      arr1 = arr1 || []
      arrayOfArrs.push(arr1)
      arr1 = []
      if (!arr1 || arr1.length === 0) {
        arr1 = arrayOfArrs.sort((a, b) => b.length - a.length)[0]
        arrayOfArrs = arrayOfArrs.filter((item) => item !== arr1)
      }
    }

    const combinedArray = mergeByDate(arr1!, arrayOfArrs)

    // find the object with the most keys
    const mostKeys = combinedArray.reduce((a, b) =>
      Object.keys(a).length > Object.keys(b).length ? a : b
    )
    const keys = Object.keys(mostKeys)
    combinedArray.forEach((row) => {
      const rowKeys = Object.keys(row)
      keys.forEach((key) => {
        if (!rowKeys.includes(key)) {
          row[key] = null
        }
      })
    })

    return orderRowsByDates(combinedArray)
  } catch (error) {
    console.warn(error)
    return []
  }
}

const evaluateZone = (
  value: number | null | string,
  zoneBoundaries: number[],
  zones: string[]
): string => {
  if (value === null) return ''

  for (let i = 0; i <= 4; i++) {
    if (Number(value) < Number(zoneBoundaries[i])) {
      return zones[i]
    }
  }

  return zones[5]
}

const transformTicksWrapperEntryIntoTicksData = ({
  entry,
  percentageDifferences,
  zoneBoundaries,
  parameters,
  residualsOverStandardDevData,
  selectedDataMappedMode
}: {
  entry: IMappedDataEntry
  percentageDifferences: IPercentageDIfferences
  zoneBoundaries: number[]
  parameters: ITrendline
  residualsOverStandardDevData: IResidualWithDate[]
  selectedDataMappedMode?: 'value' | 'arrow' | 'off'
  // comparisonEntry: IMappedDataEntry
}) => {
  try {
    residualsOverStandardDevData = residualsOverStandardDevData.filter(
      (item) => item.date !== null && item.date !== undefined
    )
    const values = residualsOverStandardDevData.map((item) => item.value)
    const dates = residualsOverStandardDevData.map((item) => item.date)

    const transformedData: ITransformedTick[] = []
    const title = entry.title

    const windowZones = Object.values(window.globalSettings.colours_data_mapped)
    const isInversed =
      Object.keys(parameters).includes('inverse') && parameters.inverse
    const zones = isInversed ? windowZones.reverse() : windowZones
    const evaluateWeight = (
      value: number,
      previousValue: number,
      direction: 'up' | 'down' | 'neutral',
      percentageDifferences: IPercentageDIfferences
    ) =>
      (direction === 'up' &&
        value - previousValue >= percentageDifferences.majorRise) ||
      (direction === 'down' &&
        value - previousValue <= percentageDifferences.majorDrop)
        ? 'double'
        : (direction === 'up' &&
              value - previousValue >= percentageDifferences.minorRise) ||
            (direction === 'down' &&
              value - previousValue <= percentageDifferences.minorDrop)
          ? 'single'
          : undefined

    const evaluateDirection = (value: number, previousValue: number) =>
      value > previousValue ? 'up' : value < previousValue ? 'down' : 'neutral'

    values.forEach((value, index) => {
      const updatedDate = dates[index]

      if (value === null) {
        transformedData.push({
          title,
          value: '',
          date: new Date(updatedDate).toISOString().split('T')[0],
          zone: '',
          direction: 'neutral',
          weight: undefined,
          id: index,
          difference: ''
        })
        return
      }

      const newValue = Number(value)

      const previousEntryValue = Number(values[index - 1])

      const direction =
        index === 0
          ? 'neutral'
          : evaluateDirection(newValue, previousEntryValue)
      const weight =
        index === 0
          ? undefined
          : evaluateWeight(
              newValue,
              previousEntryValue,
              direction,
              percentageDifferences
            )
      const zone = evaluateZone(value, zoneBoundaries, zones)

      transformedData.push({
        title: `${title} difference between`,
        value: newValue,
        date: new Date(updatedDate).toISOString().split('T')[0],
        zone,
        direction,
        weight,
        id: index,
        difference: newValue
      })
    })

    return transformedData
  } catch (error) {
    console.error("Couldn't transform the data", error)
    return []
  }
}

const changeRowsNameEntry = ({
  rows,
  keyToSub,
  newKey
}: {
  rows: IFetchedDataEntry[]
  keyToSub: string
  newKey: string
}) =>
  rows.map((row) => {
    const newRow = { ...row }
    newRow[newKey] = newRow[keyToSub]
    delete newRow[keyToSub]
    return newRow
  })

const getAllValuesGroupedByKey = (
  items: any,
  single?: boolean
): IDataMapped => {
  const obj: any = {}

  if (isEmpty(items) || items.length === 0) return { data: [] }

  const convertItemIntoNumber = (value: any) => {
    if (typeof value === 'string' && value.includes(',')) {
      const values = value.split(',')
      return values.map((v: any) => Number(v))
    } else if (typeof value === 'string' && value.includes(';')) {
      const values = value.split(';')
      return values.map((v: any) => Number(v))
    } else if (typeof value === 'string' && value.includes('%')) {
      return Number(value.replace('%', '')) / 100
    } else if (typeof value === 'string' && value.includes('£')) {
      return Number(value.replace('£', ''))
    } else if (typeof value === 'string' && value.includes('$')) {
      return Number(value.replace('$', ''))
    } else if (typeof value === 'string' && value.includes('€')) {
      return Number(value.replace('€', ''))
    } else if (typeof value === 'string' && value.includes('¥')) {
      return Number(value.replace('¥', ''))
    } else if (typeof value === 'string' && value.includes('₹')) {
      return Number(value.replace('₹', ''))
    } else if (typeof value === 'string' && value.includes('₽')) {
      return Number(value.replace('₽', ''))
    } else {
      return value
    }
  }

  if (single) {
    const key = Object.keys(items[0]).filter(
      (key) => key !== 'date' && key !== 'id'
    )[0]

    const dates = items.map(
      (item: any) => new Date(item.date).toISOString().split('T')[0]
    )
    const values = items.map((item: any) => item[key])

    return {
      data: [{ title: key, values, dates }]
    }
  } else {
    const middleIndex = Math.floor(items.length / 2)
    const keys = Object.keys(items[middleIndex]).filter(
      (key) => key !== 'date' && key !== 'id'
    )

    keys.forEach((key) => {
      obj[key] = {}
      obj[key].title = key
      items.forEach((item: any, index: number) => {
        if (index === 0) {
          obj[key].values = []
          obj[key].dates = []
        }
        let value = item[key]

        if (isEmpty(value)) {
          // find the latest non-null value and push it
          let i = index - 1
          while (i >= 0) {
            if (items[i][key]) {
              value = items[i][key]
              break
            }
            i--
          }
        }

        value = convertItemIntoNumber(value)

        if (!isEmpty(value)) {
          obj[key].values.push(value)
          obj[key].dates.push(item.date)
        } else {
          obj[key].values.push(null)
          obj[key].dates.push(item.date)
        }
      })
    })

    const data: IMappedDataEntry[] = Object.values(obj)

    return { data }
  }
}

function convertToRelativeValues(
  array: (number | string | null)[]
): (number | null)[] {
  // convert all values to the relative to the previous value
  const newArray: (number | null)[] = []
  const baseline = Number(array[0])
  array.forEach((value, index) => {
    if (index === 0) {
      newArray.push(0)
    } else if (value === null || array[index - 1] === null) {
      newArray.push(null)
    } else {
      newArray.push((Number(value) - baseline) / baseline)
    }
  })

  return newArray
}

const combineFetchedData = ({
  fetchedData,
  forecasts,
  allParams,
  fullDates,
  dataMode,
  variations,
  frequency = 'monthly'
}: {
  fetchedData: any
  forecasts?: any
  allParams: any
  fullDates: string[]
  dataMode?: string
  variations?: any
  frequency?: string
}) => {
  try {
    if (!fetchedData.length) return []

    const areThereForecasts = forecasts?.data.length > 0
    const forecastsDates = areThereForecasts
      ? forecasts.data.map((item: any) => item.date)
      : []
    const areThereVariations = variations?.data.length > 0
    const variationIds = areThereVariations
      ? variations.parameters.map((item: any) => item.fid)
      : []
    const variationsDates = areThereVariations
      ? variations.data.map((item: any) => item.date)
      : []

    if (!areThereForecasts && !areThereVariations && allParams.length === 1) {
      return fetchedData
    }
    const dataDates = fetchedData.map((item: any) => item.date)

    const tempObject: any = {}
    allParams.forEach((param: any) => {
      tempObject[param.fid] = null
    })

    let res = fullDates.map((date: string) => {
      const temp: any = { ...tempObject, date }

      allParams.forEach((param: any) => {
        if (param.type === 'forecast' && areThereForecasts) {
          const forecastIndex = findMinChartIndex(date, forecastsDates)
          temp[param.fid] =
            forecastIndex !== -1
              ? forecasts.data[forecastIndex][param.fid]
              : null
        }

        if (areThereVariations && variationIds.includes(param.fid)) {
          const variationIndex = findMinChartIndex(date, variationsDates)
          temp[param.fid] =
            variationIndex !== -1
              ? variations.data[variationIndex][param.fid]
              : null
        }
      })

      const indicatorIndex = findMinChartIndex(date, dataDates)
      const entry = fetchedData[indicatorIndex]
      if (entry) {
        Object.assign(temp, entry)
      }

      return temp
    })

    res = res.filter((row: any) =>
      Object.entries(row).some(
        ([key, value]) => value !== null && key !== 'date' && key !== 'id'
      )
    )

    // if (frequency === 'monthly') {
    //   const groupedData: any = res.reduce((acc: any, row: any) => {
    //     const date = new Date(row.date)
    //     const key = `${date.getFullYear()}-${date.getMonth()}`
    //     if (!acc[key]) acc[key] = []
    //     acc[key].push(row)
    //     return acc
    //   }, {})

    //   res = Object.values(groupedData).map(
    //     (group: any) => group[group.length - 1]
    //   )
    // }

    const keys = Object.keys(res[0]).filter(
      (key) => key !== 'date' && key !== 'id'
    )
    keys.forEach((key) => {
      if (res.every((row: any) => row[key] === null)) {
        res.forEach((row: any) => delete row[key])
      }
    })

    return res
  } catch (error) {
    console.error(error)
    return []
  }
}

const findLowestFrequencyFromParams = (rows: any[]) => {
  if (rows.length === 0) return 'monthly'
  const frequencies = rows.map((row) => row.frequency)

  if (frequencies.includes('daily')) {
    return 'daily'
  } else if (frequencies.includes('weekly')) {
    return 'weekly'
  } else if (frequencies.includes('monthly')) {
    return 'monthly'
  } else if (frequencies.includes('quarterly')) {
    return 'quarterly'
  } else if (frequencies.includes('yearly')) {
    return 'yearly'
  }

  return 'monthly'
}

const checkCalcEquationChanges = (
  equation: IEquationPiece[],
  existingEquation: IEquationPiece[]
) => {
  if (equation.length !== existingEquation.length) {
    return true
  }
  for (let i = 0; i < equation.length; i++) {
    if (equation[i].value !== existingEquation[i].value) {
      return true
    } else if (equation[i].type !== existingEquation[i].type) {
      return true
    } else if (equation[i].mode !== existingEquation[i].mode) {
      return true
    } else if (equation[i].offset !== existingEquation[i].offset) {
      return true
    } else if (equation[i].cagr !== existingEquation[i].cagr) {
      return true
    }
  }
  return false
}

interface TransformationResult {
  pieces: IEquationPiece[]
  errorCount: number
  errors: string[]
}

const transformStringEquationIntoEquationPieces = (
  equation: string,
  equationsReferences: ICombinedIndicatorFormulaAlternativeEquationBlocks
): TransformationResult => {
  const result: IEquationPiece[] = []
  let errorCount = 0
  const errors: string[] = []

  // Regular expressions for different token types
  const regex =
    /CAGR of\s+([-+]?\d+(\.\d+)?)\s*\((\w)\)|OFFSET of\s+([-+]?\d+(\.\d+)?)\s*\((\w)\)|([-+]?\d+(\.\d+)?)|([+\-*/()])|([a-h])|(\S+)/gi

  let match
  while ((match = regex.exec(equation)) !== null) {
    if (match[1]) {
      // Match for "CAGR of X (variable)"
      const cagrValue = parseFloat(match[1])
      const variable = match[3].toLowerCase()
      const ref =
        equationsReferences[
          variable as keyof ICombinedIndicatorFormulaAlternativeEquationBlocks
        ]
      if (ref) {
        result.push({
          value: ref.fid,
          type: 'indicator',
          offset: 0,
          mode: ref.mode || 'values',
          cagr: cagrValue
        })
      } else {
        // Undefined variable reference
        errorCount++
        errors.push(
          `Undefined reference for variable '${variable}' in CAGR expression.`
        )
      }
    } else if (match[4]) {
      // Match for "OFFSET of X (variable)"
      const offsetValue = parseFloat(match[4])
      const variable = match[6].toLowerCase()
      const ref =
        equationsReferences[
          variable as keyof ICombinedIndicatorFormulaAlternativeEquationBlocks
        ]
      if (ref) {
        result.push({
          value: ref.fid,
          type: 'indicator',
          offset: offsetValue,
          mode: ref.mode || 'values',
          cagr: 0
        })
      } else {
        // Undefined variable reference
        errorCount++
        errors.push(
          `Undefined reference for variable '${variable}' in OFFSET expression.`
        )
      }
    } else if (match[7]) {
      // Match for numbers
      result.push({
        value: match[7],
        type: 'number',
        offset: 0,
        mode: 'values',
        cagr: 0
      })
    } else if (match[9]) {
      // Match for operators and parentheses
      result.push({
        value: match[9],
        type: 'operator',
        offset: 0,
        mode: 'values',
        cagr: 0
      })
    } else if (match[10]) {
      // Match for variables
      const variable = match[10].toLowerCase()
      const ref =
        equationsReferences[
          variable as keyof ICombinedIndicatorFormulaAlternativeEquationBlocks
        ]
      if (ref) {
        result.push({
          value: ref.fid,
          type: 'indicator',
          offset: 0,
          mode: ref.mode || 'values',
          cagr: 0
        })
      } else {
        // Undefined variable reference
        errorCount++
        errors.push(`Undefined reference for variable '${variable}'.`)
      }
    } else if (match[11]) {
      // Match for unrecognized tokens (errors)
      errorCount++
      errors.push(`Unrecognized token '${match[11]}'.`)
    }
    // Ignore whitespace matches (match[12] if any)
  }

  return { pieces: result, errorCount, errors }
}

interface TransformationEquationResult {
  equation: string
  errorCount: number
  errors: string[]
}

const transformEquationPiecesIntoStringEquation = (
  pieces: IEquationPiece[],
  equationsReferences: ICombinedIndicatorFormulaAlternativeEquationBlocks
): TransformationEquationResult => {
  let equation = ''
  let errorCount = 0
  const errors: string[] = []

  // Create a mapping from fid to variable for reverse lookup
  const fidToVariableMap: { [fid: string]: string } = {}
  for (const variable in equationsReferences) {
    const ref =
      equationsReferences[
        variable as keyof ICombinedIndicatorFormulaAlternativeEquationBlocks
      ]
    if (ref && ref.fid) {
      fidToVariableMap[ref.fid] = variable
    }
  }

  for (const piece of pieces) {
    if (piece.type === 'indicator') {
      const fid = piece.value
      const variable = fidToVariableMap[fid]

      if (!variable) {
        errorCount++
        errors.push(`Undefined reference for fid '${fid}' in indicator piece.`)
        continue // Skip this piece as we cannot map it back to a variable
      }

      if (piece.cagr > 0) {
        // Represent as "CAGR of X (variable)"
        equation += `CAGR of ${piece.cagr} (${variable}) `
      } else if (piece.offset !== 0) {
        // Represent as "OFFSET of X (variable)"
        equation += `OFFSET of ${piece.offset} (${variable}) `
      } else {
        // Just the variable
        equation += `${variable} `
      }
    } else if (piece.type === 'number') {
      equation += `${piece.value} `
    } else if (piece.type === 'operator') {
      equation += `${piece.value} `
    } else {
      // Unrecognized type
      errorCount++
      errors.push(`Unrecognized piece type '${piece.type}'.`)
    }
  }

  // Trim any trailing whitespace
  equation = equation.trim()

  return { equation, errorCount, errors }
}

const transformOverlayDataIntoParameters = ({
  chartMinValue,
  chartMaxValue,
  chartHeightInPixels,
  arrayOfBoundaries,
  arrayOfColours
}: IOverlaysInputsToBeTransformedIntoZones) => {
  const range = chartMaxValue - chartMinValue
  const result: ITransformedOverlayZonePareters[] = []
  arrayOfBoundaries.forEach((boundary, index) => {
    boundary = Number(boundary)
    if (index === 0) {
      result.push({
        offsetTop: 0,
        height: ((chartMaxValue - boundary) / range) * chartHeightInPixels,
        colour: arrayOfColours[index]
      })
    } else if (index === arrayOfBoundaries.length - 1) {
      result.push({
        offsetTop: result[index - 1].height + result[index - 1].offsetTop,
        height:
          ((arrayOfBoundaries[index - 1] - boundary) / range) *
          chartHeightInPixels,
        colour: arrayOfColours[index]
      })
      result.push({
        offsetTop: result[index].height + result[index].offsetTop,
        height: ((boundary - chartMinValue) / range) * chartHeightInPixels,
        colour: arrayOfColours[index + 1]
      })
    } else {
      result.push({
        offsetTop: result[index - 1].height + result[index - 1].offsetTop,
        height:
          ((arrayOfBoundaries[index - 1] - boundary) / range) *
          chartHeightInPixels,
        colour: arrayOfColours[index]
      })
    }
  })
  return result
}

const generateIndicatorsKeysObjects = ({
  indicatorsParameters,
  selectedChart,
  selectedData,
  dataMode,
  backupVisibleKeys
}: {
  indicatorsParameters: ITrendline[]
  backupVisibleKeys: string[]
  selectedData?: string
  selectedChart?: string
  dataMode?: string
}) => {
  let allChartKeys: IEntityAllKeys[] = []
  let allDataKeys: IEntityAllKeys[] = []

  const allKeys: IEntityAllKeys[] = indicatorsParameters.map((item: any) => ({
    id: item.id,
    title: item.fid,
    name: item.title,
    category: item.category,
    type: item.type,
    base_indicator: item.base_indicator,
    description: item.short_description,
    disabled: dataMode
      ? dataMode === 'deviations' && item.type !== 'trendline'
      : false
  }))

  if (!isEmpty(selectedData) && selectedData) {
    allDataKeys = allKeys.filter((item) => {
      const cols = selectedData.split(', ')
      const ind = cols.indexOf(item.title)
      return cols[ind] === item.title
    })
  }

  if (!isEmpty(selectedChart) && selectedChart) {
    allChartKeys = allKeys.filter((item) => {
      const cols = selectedChart.split(', ')
      const ind = cols.indexOf(item.title)
      return cols[ind] === item.title
    })
  }

  if (backupVisibleKeys.length > 0 && allDataKeys.length === 0) {
    backupVisibleKeys.forEach((id: string) => {
      const item = allKeys.find((key) => key.title === id)
      if (item) {
        // check if the item is already in the allDataKeys
        const exists = allDataKeys.find((key) => key.title === id)
        if (!exists) {
          allDataKeys.push(item)
        }
      }
    })
  }

  if (backupVisibleKeys.length > 0 && allChartKeys.length === 0) {
    backupVisibleKeys.forEach((id: string) => {
      const item = allKeys.find((key) => key.title === id)
      if (item) {
        // check if the item is already in the allChartKeys
        const exists = allChartKeys.find((key) => key.title === id)
        if (!exists) {
          allChartKeys.push(item)
        }
      }
    })
  }

  return {
    allKeys,
    allChartKeys,
    allDataKeys
  }
}

// Change getMaxDifference to return an array of max differences for each series
const getMaxDifferences = (data: any[][]): number[] => {
  const maxDifferences: number[] = []
  if (data.length === 0) return maxDifferences

  for (let j = 1; j < data[0].length; j++) {
    let maxDiffForSeries = 0
    for (let i = 1; i < data.length; i++) {
      for (let k = i + 1; k < data.length; k++) {
        const difference = Math.abs(data[i][j] - data[k][j])
        if (difference > maxDiffForSeries) {
          maxDiffForSeries = difference
        }
      }
    }
    maxDifferences.push(maxDiffForSeries)
  }

  const min = maxDifferences
    .filter((value) => value !== 0)
    .sort((a, b) => a - b)[0]
  const indexes = maxDifferences.map((value) => (value / min > 10 ? 1 : 0))

  return indexes
}

// const IsRowWithoutProlongedZeros = (row: any) => {
//   let consecutiveZeros = 0
//   for (const value of row) {
//     if (value === 0) {
//       consecutiveZeros += 1
//     } else {
//       consecutiveZeros = 0
//     }

//     if (consecutiveZeros > 2) {
//       return false
//     }
//   }
//   return true
// }

// const findColumnWithAllZeros = (data: any[][]) => {
//   const columnsWithAllZeros: number[] = []
//   for (let i = 1; i < data[0].length; i++) {
//     let allZeros = true
//     for (let j = 1; j < data.length; j++) {
//       if (data[j][i] > 1) {
//         allZeros = false
//         break
//       }
//     }
//     if (allZeros) {
//       columnsWithAllZeros.push(i)
//     }
//   }
//   return columnsWithAllZeros
// }

const formatDate = (isoString: any): Date => new Date(isoString)

const convertFetchedDataToHighcharts = (data: IMappedDataEntry) => {
  const result: (number | null)[][] = []
  const dates = data.dates.map((date) => new Date(date).getTime())
  data.values.forEach((value, index) => {
    const res: (number | null)[] = [dates[index]]

    if (value === null) {
      res.push(null)
    } else {
      res.push(Number(value))
    }

    result.push(res)
  })

  // filter out the rows with null values
  return result.filter((row) => row[1] !== null)
}

const transformRawDataToGoogleChartInputData = ({
  inputData,
  mode
}: {
  inputData: IGoogleChartWithRangeFilterData
  mode: 'absolute' | 'relativeToFirstValue' | 'differences'
}) => {
  if (inputData.fetchedData.length === 0) return []

  const firstRow = Object.keys(inputData.fetchedData[0]).filter
    ? Object.keys(inputData.fetchedData[0]).filter(
        (key) =>
          key !== 'id' &&
          (inputData.filteredKeys.includes(key) || key === 'date')
      )
    : Object.keys(inputData.fetchedData[0])

  // reorder put date in the first column
  const dateIndex = firstRow.indexOf('date')
  if (dateIndex !== 0) {
    const temp = firstRow[0]
    firstRow[0] = firstRow[dateIndex]
    firstRow[dateIndex] = temp
  }

  const rawData: any[][] = []
  const dataClone = inputData.fetchedData.map((row: any) => ({ ...row }))

  inputData.fetchedData.forEach((row: any, rowIndex: number) => {
    const newObj = Object.fromEntries(
      Object.entries(row).filter(
        ([key, value]) =>
          key !== 'id' &&
          (inputData.filteredKeys.includes(key) || key === 'date')
      )
    )
    const transformedRow: any = []

    Object.entries(newObj).forEach(([key, value], index) => {
      if (key === 'date') {
        const date = formatDate(value)

        // push to the first column and move the rest
        return transformedRow.unshift(date)
      }

      if (value === null) {
        return transformedRow.push(null)
      }

      const numericValue =
        typeof value === 'string' && value.includes('%')
          ? Number(value.replace('%', '')) / 100
          : Number(value)

      if (mode === 'relativeToFirstValue') {
        if (rowIndex === 0) {
          return transformedRow.push(null)
        }
        let firstNonNullValue = null

        for (let i = 0; i <= rowIndex; i++) {
          const currentValue = inputData.fetchedData[i][key]
          if (currentValue !== null) {
            firstNonNullValue = currentValue
            break
          }
        }
        if (firstNonNullValue === null) {
          return transformedRow.push(null)
        }
        const relativeValue = numericValue / Number(firstNonNullValue) - 1
        return relativeValue
      } else if (mode === 'differences') {
        if (rowIndex === 0) {
          return transformedRow.push(null)
        }
        const previousValue = dataClone[rowIndex - 1][key]
        if (previousValue === null) {
          return transformedRow.push(null)
        }
        if (Number(previousValue) === 0) {
          return transformedRow.push(null)
        }
        const difference =
          (numericValue - Number(previousValue)) / Number(previousValue)
        return transformedRow.push(difference)
      }

      return transformedRow.push(numericValue)
    })
    rawData.push(transformedRow)
  })
  const res = [firstRow, ...rawData]
  return res
}

function exportToExcel(data: any[], fileName: string = 'ExportedData') {
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data)
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

  /* save to file */
  XLSX.writeFile(wb, `${fileName}.xlsx`)
}

const computeGapAnalysis = async ({
  indicatorData,
  baseIndicator,
  analysisMode,
  indicatorId
}: {
  indicatorData: any[]
  baseIndicator: string
  analysisMode: string
  indicatorId: string
}) => {
  if (!baseIndicator) {
    return ''
  }
  const response = await fetchChosenIndicator(baseIndicator)
  if (!response) {
    return ''
  }
  const baselineIndicatorData = response.fetchedIndicatorData
  if (!baselineIndicatorData || baselineIndicatorData.length === 0) {
    return ''
  }
  if (analysisMode === 'relativeAverage') {
    const newData: number[] = []
    baselineIndicatorData.forEach((baselineIndicatorDataItem: any) => {
      const dataItem = indicatorData.find((item: any) =>
        compareDates(item.date, baselineIndicatorDataItem.date)
      )
      const indicatorDataItem = Number(dataItem ? dataItem[indicatorId] : null)
      const baseData = Number(baselineIndicatorDataItem[baseIndicator])

      if (indicatorDataItem) {
        const calculated = (indicatorDataItem - baseData) / baseData
        newData.push(calculated)
      }
    })
    if (newData.length === 0) {
      return ''
    }
    const average = newData.reduce((a, b) => a + b, 0) / newData.length

    return average.toString().slice(0, 5) + '%'
  } else if (analysisMode === 'relativeMedian') {
    const newData: number[] = []
    baselineIndicatorData.forEach((baselineIndicatorDataItem: any) => {
      const dataItem = indicatorData.find((item: any) =>
        compareDates(item.date, baselineIndicatorDataItem.date)
      )
      const indicatorDataItem = Number(dataItem ? dataItem[indicatorId] : null)
      const baseData = Number(baselineIndicatorDataItem[baseIndicator])

      if (indicatorDataItem) {
        const calculated = (indicatorDataItem - baseData) / baseData
        newData.push(calculated)
      }
    })

    if (newData.length === 0) {
      return ''
    }

    const sorted = newData.sort((a, b) => a - b)
    const median =
      sorted.length % 2 === 0
        ? (sorted[sorted.length / 2 - 1] + sorted[sorted.length / 2]) / 2
        : sorted[(sorted.length - 1) / 2]
    return median.toString().slice(0, 5) + '%'
  } else if (analysisMode === 'absoluteAverage') {
    const newData: number[] = []
    baselineIndicatorData.forEach((baselineIndicatorDataItem: any) => {
      const dataItem = indicatorData.find((item: any) =>
        compareDates(item.date, baselineIndicatorDataItem.date)
      )
      const indicatorDataItem = Number(dataItem ? dataItem[indicatorId] : null)
      const baseData = Number(baselineIndicatorDataItem[baseIndicator])

      if (indicatorDataItem) {
        const calculated = indicatorDataItem - baseData
        newData.push(calculated)
      }
    })
    if (newData.length === 0) {
      return ''
    }
    const average = newData.reduce((a, b) => a + b, 0) / newData.length

    return average.toString().slice(0, 5)
  } else if (analysisMode === 'absoluteMedian') {
    const newData: number[] = []
    baselineIndicatorData.forEach((baselineIndicatorDataItem: any) => {
      const dataItem = indicatorData.find((item: any) =>
        compareDates(item.date, baselineIndicatorDataItem.date)
      )
      const indicatorDataItem = Number(dataItem ? dataItem[indicatorId] : null)
      const baseData = Number(baselineIndicatorDataItem[baseIndicator])

      if (indicatorDataItem) {
        const calculated = indicatorDataItem - baseData
        newData.push(calculated)
      }
    })
    if (newData.length === 0) {
      return ''
    }
    const sorted = newData.sort((a, b) => a - b)
    const median =
      sorted.length % 2 === 0
        ? (sorted[sorted.length / 2 - 1] + sorted[sorted.length / 2]) / 2
        : sorted[(sorted.length - 1) / 2]

    return median.toString().slice(0, 5)
  } else if (analysisMode === 'standardDeviation') {
    const newData: number[] = []
    baselineIndicatorData.forEach((baselineIndicatorDataItem: any) => {
      const dataItem = indicatorData.find((item: any) =>
        compareDates(item.date, baselineIndicatorDataItem.date)
      )
      const indicatorDataItem = Number(dataItem ? dataItem[indicatorId] : null)
      const baseData = Number(baselineIndicatorDataItem[baseIndicator])

      if (indicatorDataItem) {
        const calculated = indicatorDataItem - baseData
        newData.push(calculated)
      }
    })
    if (newData.length === 0) {
      return ''
    }
    const average = newData.reduce((a, b) => a + b, 0) / newData.length
    const variance =
      newData
        .map((item) => Math.pow(item - average, 2))
        .reduce((a, b) => a + b, 0) / newData.length
    const standardDeviation = Math.sqrt(variance)
    return standardDeviation.toString().slice(0, 5)
  } else if (analysisMode === 'coefficientOfVariation') {
    const newData: number[] = []
    baselineIndicatorData.forEach((baselineIndicatorDataItem: any) => {
      const dataItem = indicatorData.find((item: any) =>
        compareDates(item.date, baselineIndicatorDataItem.date)
      )
      const indicatorDataItem = Number(dataItem ? dataItem[indicatorId] : null)
      const baseData = Number(baselineIndicatorDataItem[baseIndicator])

      if (indicatorDataItem) {
        const calculated = indicatorDataItem - baseData
        newData.push(calculated)
      }
    })
    if (newData.length === 0) {
      return ''
    }
    const average = newData.reduce((a, b) => a + b, 0) / newData.length
    const variance =
      newData
        .map((item) => Math.pow(item - average, 2))
        .reduce((a, b) => a + b, 0) / newData.length
    const standardDeviation = Math.sqrt(variance)
    const coefficientOfVariation = standardDeviation / average
    return coefficientOfVariation.toString().slice(0, 5)
  }
  return ''
}

const transformFREDIndicatorsToPlatformIndicators = (
  response: DataSeries[]
): (IBasicIndicator | null)[] => {
  if (!response) return []
  return response.map((indicator, index) => {
    const platformIndicator: IBasicIndicator = {
      fid: indicator.id,
      title: indicator.title,
      type: 'external',
      category: 'FRED',
      ids_included: [],
      short_description: indicator.notes,
      meaning:
        indicator.notes +
        ' Data extracted from FRED API. Chartit360 does not own this data and is not affiliated with FRED.',
      is_own: !isEmpty(localStorage.getItem('token')),
      standard_deviation: 0,
      standard_deviation_chart_mode: 'exponential',
      standard_deviation_parameters: JSON.stringify({
        a: 1,
        b: 1
      }),
      standard_deviation_relative: false,
      frequency: indicator.frequency,
      columns_selected: '',
      chart_columns_selected: '',
      range_chosen: '',
      range_chosen_deviation: '',
      tags: [],
      id: index,
      is_public: true,
      data_mode: 'values',
      ChannelContentPiece: null,
      admin_access: false,
      inverse: false,
      range_complete: '',
      user_id: ''
    }

    return platformIndicator
  })
}

const paddWithNulls = (arr: number[], nullsStart: number, nullsEnd: number) => {
  const nullsArray = new Array(nullsEnd - nullsStart).fill(null)
  return [...nullsArray, ...arr]
}

const calculateTheFrequency = (
  firstDate: string | Date,
  secondDate: string | Date
): Frequency => {
  const date1 = new Date(firstDate)
  const date2 = new Date(secondDate)

  const diffTime = Math.abs(date2.getTime() - date1.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  if (diffDays < 7) {
    return 'daily'
  } else if (diffDays < 31) {
    return 'weekly'
  } else if (diffDays < 100) {
    return 'monthly'
  } else if (diffDays < 365) {
    return 'quarterly'
  } else {
    return 'yearly'
  }
}

const findDataFrequency = (dates: (string | Date)[]): Frequency => {
  if (dates.length < 2) return 'monthly'

  const frequencies: Frequency[] = []

  for (let i = 0; i < 100; i++) {
    const firstIndex = Math.floor(Math.random() * (dates.length - 1))
    const frequency = calculateTheFrequency(
      dates[firstIndex],
      dates[firstIndex + 1]
    )

    frequencies.push(frequency)
  }
  // eslint-disable-next-line
  const frequencyCount: { [key in Frequency]?: number } = frequencies
    .filter((frequency) => frequency !== null)
    .reduce(
      (acc, frequency) => {
        if (!acc[frequency]) {
          acc[frequency] = 1
        } else {
          acc[frequency]!++
        }

        return acc
      },
      {} as { [key in Frequency]?: number } // eslint-disable-line
    )
  const mostCommonFrequency = Object.keys(frequencyCount).reduce((a, b) =>
    (frequencyCount[a as Frequency] || 0) >
    (frequencyCount[b as Frequency] || 0)
      ? a
      : b
  )

  return mostCommonFrequency as Frequency
}

export {
  findDataFrequency,
  calculateTheFrequency,
  transformTicksWrapperEntryIntoTicksData,
  transformOverlayDataIntoParameters,
  getAllValuesGroupedByKey,
  transformRawDataToGoogleChartInputData,
  generateIndicatorsKeysObjects,
  getMaxDifferences,
  evaluateZone,
  exportToExcel,
  transformSettings,
  computeGapAnalysis,
  mergeByDate,
  returnMergedByDate,
  generateRandomNumber,
  paddWithNulls,
  findClosestMidnight,
  findClosestMidnightString,
  transformAndSortSidePanelEntities,
  mergeValuesAndTrendlinesByDate,
  combineFetchedData,
  orderRowsByDates,
  getExistingColumns,
  checkCalcEquationChanges,
  convertExcelDateToHTMLDate,
  transformFREDIndicatorsToPlatformIndicators,
  transformAndSortAllEntities,
  changeRowsNameEntry,
  findLowestFrequencyFromParams,
  transformEquationPiecesIntoStringEquation,
  transformStringEquationIntoEquationPieces,
  convertFetchedDataToHighcharts,
  convertToRelativeValues
}
