import React, { Fragment, useEffect, useRef, useState } from 'react'
import {
  modifyCollectionEntries,
  deleteIndicatorByName,
  fetchChosenIndicator,
  updateIndicarorDetails,
  updateRangeSelected,
  updateIndicatorCalculatedComputationalMode,
  updateEntityInverse,
  updateIndicatorDataMode,
  createNewForecast,
  createNewIndicatorCalculated,
  togglePrimarySettingCalculatedIndicator,
  updateEquationCalculatedIndicator
} from '../utils/fetch'
import {
  // ICollection,
  ICollectionEntry,
  ICalculatedIndicator,
  ITrendline,
  IDataMapped,
  ICalculatedIndicatorPage,
  IBasicIndicator,
  IEquationPiece,
  IIncludedId
} from '../utils/interfaces'
import PopupModal from '../helperComponents/PopUpModal'
import {
  TabbingSwitch,
  AccessDenied,
  FunctionalButton,
  Spinner,
  FunctionalCheckbox,
  EntityHeader,
  ScrollTableButtons
} from '../helperComponents/_components'
import {
  Icon3Dots // IconCollapse,
  // IconExpand
  // IconExpandMore
} from '../helperComponents/Icons'
import {
  generateTitlesForFidsInAString,
  isEmpty,
  transformFetchedRangedDetailsScenariotoRangeInputs
} from '../utils/functions'
import {
  exportToExcel,
  getAllValuesGroupedByKey,
  returnMergedByDate
} from '../utils/transformingData'
import { MyTreeChart } from '../charts/GoogleCharts'
import CustomContextMenu from '../helperComponents/CustomContextMenu'
import UploadPage from '../helperComponents/UploadPage'

import RangeSlider2 from '../helperComponents/RangeSlider2'
import EntityTags from '../helperComponents/EntityTags'
import GapAnalysis from '../helperComponents/GapAnalysis'
import CombineIndicatorFormula from '../helperComponents/CombineIndicatorFormula'
import DataTableNew from '../helperComponents/DataTableNew'
import EntitySharingControls from '../helperComponents/EntitySharingControls'
import ChatbotComponent from '../helperComponents/ChatbotComponent'
import CreateTrendline from '../trendlines/CreateTrendline'
import { HighChartsLinearChart } from '../charts/HighChartsReact'
import RenameMajorEntity from '../helperComponents/RenameMajorEntity'
import AddRemoveSelectedPopup from '../popups/EntityPages/AddRemoveSelectedPopup'

const CalculatedIndicator = ({
  indicatorId,
  userTags,
  favourites,
  readonly,
  inner,
  scenarios
}: ICalculatedIndicatorPage) => {
  try {
    // const [selectedDataMappedMode, setSelectedDataMappedMode] = useState<
    //   'value' | 'arrow' | 'off'
    // >(window.globalSettings.rises_and_drops_in_sd.state)

    const fetchedIndicatorParameters = useRef<ICalculatedIndicator>(
      {} as ICalculatedIndicator
    )
    // const [enabledKeys, setEnabledKeys] = useState<string[]>([])
    const [readOnly, setReadOnly] = useState<boolean>(readonly)
    // eslint-disable-next-line
    const allFetchedIndicators = useRef<
      (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
    >([])
    const [modalIndicatorEditData, setModalIndicatorEditData] =
      React.useState(false)
    const [modalCreateNewVariation, setModalCreateNewVariation] =
      React.useState(false)
    const [modalEditConfiguration, setModalEditConfiguration] = React.useState({
      open: false,
      fid: ''
    })
    const [newVariationName, setNewVariationName] = React.useState('')
    const [modalCreateTrendline, setModalCreateTrendline] =
      useState<boolean>(false)

    const files = useRef<File[] | null>(null)
    const [accessAllowed, setAccessAllowed] = React.useState(false)
    const [fetchedIndicatorData, setFetchedIndicatorData] = React.useState(
      {} as any
    )

    const fetchedDataTransformed = useRef<IDataMapped>({} as IDataMapped)
    // eslint-disable-next-line
    const fetchedAllIndicators = useRef<
      (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
    >([])

    const [fullDates, setFullDates] = React.useState<string[]>([])
    const [calculatorVisible, setCalculatorVisible] = React.useState(false)
    // const [dataMappedCellWidth, setDataMappedCellWidth] = useState<number>(50)
    const [detailsTabMode, setDetailsTabMode] = useState<string>('details')
    const [pageLoading, setPageLoading] = React.useState(false)
    const [modalDeleteIndicator, setModalDeleteIndicator] =
      React.useState(false)
    const defaultContextMenuSettings = {
      top: 0,
      left: 0,
      show: false
    }

    const [contextMenuDetails, setContextMenuDetails] = useState(
      defaultContextMenuSettings
    )
    const [
      modalIndicatorNameAndDescription,
      setModalIndicatorNameAndDescription
    ] = useState(false)

    const [selectedDataBoundaries, setSelectedDataBoundaries] = useState<
      string[]
    >([])

    const [modalEntityRange, setModalEntityRange] = useState<boolean>(false)
    const [includedIds, setIncludedIds] = useState<IIncludedId[]>([])
    const [indicatorDataMode, setIndicatorDataMode] = useState<string>('values')
    const [indicatorComputationMode, setIndicatorComputationMode] =
      useState<string>('')
    const [ownerInfo, setOwnerInfo] = useState({
      profileImage: '',
      username: ''
    })

    const [baseCompositeIndicatorId, setBaseCompositeIndicatorId] =
      useState<string>('')
    const [delayedStartDate, setDelayedStartDate] = useState<string>('')
    const [delayedInitialDate, setDelayedInitialDate] = useState<string>('')

    const [offsetStarting, setOffsetStarting] = useState<string>('1')
    const [selectedIndicator, setSelectedIndicator] = useState<string>('')

    // const [modalChartExpanded, setModalChartExpanded] = useState<boolean>(true)
    const [showRelationalTree, setShowRelationalTree] = useState<boolean>(false)
    // const [fetchedCollections, setFetchedCollections] = useState<ICollection[]>(
    //   []
    // )

    const [changesToInputs, setChangesToInputs] = useState<boolean>(false)

    const [isCurrentIndicatorFavourite, setIsCurrentIndicatorFavourite] =
      useState(false)
    const resetContextMenuDetails = () => {
      if (contextMenuDetails.show) {
        setContextMenuDetails(defaultContextMenuSettings)
      }
    }

    const [modeChanged, setModeChanged] = useState(false)

    const showCalculator = (computationMode?: string) => {
      const localMode = computationMode || indicatorComputationMode
      const isCalculatorVisible =
        !isEmpty(localMode) &&
        (localMode.includes('composite') ||
          localMode.includes('deviations') ||
          localMode.includes('calculated') ||
          localMode.includes('delayed') ||
          localMode.includes('delayedTotal'))

      setCalculatorVisible(isCalculatorVisible)
    }

    const checkChanges = () => {
      const initialMode =
        fetchedIndicatorParameters.current?.computation_mode &&
        fetchedIndicatorParameters.current?.computation_mode.includes('---')
          ? fetchedIndicatorParameters.current?.computation_mode.split('---')
          : fetchedIndicatorParameters.current?.computation_mode

      if (!initialMode) {
        return false
      }

      return (
        (offsetStarting !== initialMode[1] &&
          indicatorComputationMode === 'offset') ||
        (delayedInitialDate !== initialMode[1] &&
          indicatorComputationMode === 'delayed') ||
        (delayedInitialDate !== initialMode[1] &&
          indicatorComputationMode === 'delayedTotal') ||
        (delayedStartDate !== initialMode[2] &&
          indicatorComputationMode === 'delayed') ||
        (delayedStartDate !== initialMode[2] &&
          indicatorComputationMode === 'delayedTotal') ||
        (baseCompositeIndicatorId !== initialMode[1] &&
          indicatorComputationMode === 'composite')
      )
    }

    const resetModals = () => {
      window.collapseSidePanel(true)
      setModalDeleteIndicator(false)
      setModalIndicatorNameAndDescription(false)
      setModalCreateTrendline(false)
      // setModalChartExpanded(false);

      setContextMenuDetails(defaultContextMenuSettings)
      setNewVariationName('')
      setModalCreateNewVariation(false)
      setModalIndicatorEditData(false)
      setShowRelationalTree(false)
      setModalEntityRange(false)
      setModalEditConfiguration({ open: false, fid: '' })
    }

    const handleContextMenuDetails = (e: any) => {
      e.preventDefault()
      if (contextMenuDetails.show) {
        setContextMenuDetails(defaultContextMenuSettings)
        return
      }
      resetModals()
      setContextMenuDetails({
        show: true,
        top: e.pageY,
        left: e.pageX + 10
      })
    }

    const checkIfIndicatorIsFavourite = () => {
      if (favourites === undefined) {
        return false
      }

      const isFavourite =
        favourites.calculated_indicators.length > 0 &&
        favourites.calculated_indicators.find(
          (item: ICollectionEntry) => item.id === indicatorId
        ) !== undefined

      return isFavourite
    }

    // const filterOutIndicatorKey = (key: IEntityAllKeys) =>
    //   key.title !== indicatorId

    // const filterOutIndicatorKeyAndTrendlines = (key: IEntityAllKeys) =>
    //   key.title !== indicatorId && key.base_indicator !== indicatorId

    const handleUpdateComputationalMode = async () => {
      const newMode = indicatorComputationMode.includes('offset')
        ? indicatorComputationMode + '---' + offsetStarting
        : indicatorComputationMode.includes('delayed') ||
            indicatorComputationMode.includes('delayedTotal')
          ? indicatorComputationMode +
            '---' +
            delayedInitialDate +
            '---' +
            delayedStartDate
          : indicatorComputationMode + '---' + baseCompositeIndicatorId

      const res = await updateIndicatorCalculatedComputationalMode({
        indicatorId: modalEditConfiguration.fid,
        newMode,
        dataMode: indicatorDataMode
      })

      return res
    }

    const handleUpdateEquationByFid = async (equation: IEquationPiece[]) => {
      const res1 = await handleUpdateComputationalMode()

      if (!res1) return false
      const res2 = await updateEquationCalculatedIndicator({
        equation,
        indicatorId
      })

      return res2
    }

    const handleOpenConfigurationModal = (fid: string) => {
      setModalEditConfiguration({ open: true, fid })
    }

    const fetchIndicator = async (indicatorId: string, noClose?: boolean) => {
      if (pageLoading) return

      try {
        if (!noClose) {
          resetModals()
          setPageLoading(true)
        }

        const response = await fetchChosenIndicator(indicatorId)
        if (!response || response.fetchedIndicatorParameters.length === 0) {
          setPageLoading(false)
          setAccessAllowed(false)
          return
        }

        const fetchedParameters: ICalculatedIndicator =
          response.fetchedIndicatorParameters[0]
        const { trendlines, forecasts, variations } = response

        let allParams = [
          ...response.fetchedIndicatorParameters,
          ...trendlines,
          ...forecasts.parameters,
          ...variations.parameters
        ]

        // Remove duplicates, prioritizing the later occurrences
        allParams = allParams
          .reverse()
          .filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.fid === item.fid)
          )
          .reverse()

        allParams = allParams.map(
          (ind: ICalculatedIndicator | ITrendline | IBasicIndicator) => {
            if (ind.type === 'calculated') {
              return {
                ...ind,
                onclick: handleOpenConfigurationModal
              }
            }
            return ind
          }
        )

        const combinedData = returnMergedByDate(undefined, [
          response.fetchedIndicatorData,
          forecasts?.data || [],
          variations?.data || []
        ])

        if (combinedData.length === 0) {
          handleOpenConfigurationModal(indicatorId)
        }

        const transformed = getAllValuesGroupedByKey(combinedData)
        const transformedRange =
          transformFetchedRangedDetailsScenariotoRangeInputs(
            fetchedParameters.range_chosen
          )
        const isFavourite = checkIfIndicatorIsFavourite()
        const idsIncluded =
          fetchedParameters.ids_included &&
          fetchedParameters.ids_included.length > 0
            ? fetchedParameters.ids_included
            : []

        setReadOnly(!fetchedParameters.is_own)
        setOwnerInfo({
          profileImage: response.owner.profile_image,
          username: response.owner.username
        })
        if (!isEmpty(fetchedParameters.computation_mode)) {
          const parts = fetchedParameters.computation_mode.split('---')
          switch (parts[0]) {
            case 'composite':
              setIndicatorComputationMode('composite')
              setBaseCompositeIndicatorId(parts[1] || '')
              break
            case 'trendline':
              setIndicatorComputationMode('trendline')
              setBaseCompositeIndicatorId(parts[1] || '')
              break
            case 'delayed':
              setIndicatorComputationMode('delayed')
              setDelayedInitialDate(parts[1] || '')
              setDelayedStartDate(parts[2] || '')
              break
            case 'delayedTotal':
              setIndicatorComputationMode('delayedTotal')
              setDelayedInitialDate(parts[1] || '')
              setDelayedStartDate(parts[2] || '')
              break
            case 'calculated':
              setIndicatorComputationMode('calculated')
              break
            default:
              setIndicatorComputationMode(
                fetchedParameters.computation_mode || ''
              )
              break
          }
        } else {
          setIndicatorComputationMode('')
        }

        setFetchedIndicatorData(combinedData)
        setIndicatorDataMode(fetchedParameters.data_mode)
        fetchedIndicatorParameters.current = fetchedParameters
        allFetchedIndicators.current = allParams
        setFullDates(response.fullDates)
        fetchedAllIndicators.current = response.allIndicators.map(
          (item: any) => {
            if (item.type !== 'calculated') return item
            return allParams.find((ind: any) => ind.fid === item.fid) || item
          }
        )

        fetchedDataTransformed.current = transformed
        setSelectedDataBoundaries(transformedRange)
        setIsCurrentIndicatorFavourite(isFavourite)
        setIncludedIds(idsIncluded)
        setAccessAllowed(true)
        showCalculator(fetchedParameters.computation_mode)
        if (fetchedParameters.equation.length === 0) {
          setDetailsTabMode('calculated')
        }
        if (!noClose) {
          setPageLoading(false)
        }
        setModeChanged(false)
      } catch (err: any) {
        console.error("Couldn't fetch indicator", err)
        setPageLoading(false)
      }
    }

    const filterBasedOnMode = (
      ind: IBasicIndicator | ICalculatedIndicator | ITrendline
    ) => {
      const isTrendline = ind.type === 'trendline'

      if (indicatorDataMode === 'values') {
        return !isTrendline
      }

      return isTrendline
    }

    const refreshIndicator = async () => {
      if (pageLoading) return
      await fetchIndicator(indicatorId)
    }

    const handleRename = async ({
      newName,
      newDescription,
      newShortDescription
    }: any) => {
      const res = await updateIndicarorDetails({
        indicatorId,
        newName,
        newDescription,
        newShortDescription
      })

      if (res) {
        setModalIndicatorNameAndDescription(false)
        refreshIndicator()
      }

      return res
    }

    // const addIndicatorToShown = async (fid: string) => {
    //   const res = await updateSelectedColumnsEntity({
    //     selectedChartList: [
    //       ...entityChartKeys.map((item) => item.title),
    //       fid
    //     ].join(', '),
    //     selectedDataList: [
    //       ...entityChartKeys.map((item) => item.title),
    //       fid
    //     ].join(', '),
    //     entityId: indicatorId,
    //     entityType: 'calculated'
    //   })

    //   if (res) {
    //     return fid
    //   }
    // }

    const refreshIndicatorNoClose = async (contextMenu?: boolean) => {
      if (pageLoading) return

      await fetchIndicator(indicatorId, true).then(() => {
        if (contextMenu) {
          resetContextMenuDetails()
        }
      })
    }

    const handleCreateNewVariation = async () => {
      const res = await createNewIndicatorCalculated({
        title: newVariationName,
        dataMode: indicatorDataMode,
        computationMode: indicatorComputationMode,
        base: `calculated---${indicatorId}`
      })

      if (res) {
        // res = await addIndicatorToShown(res)
        setModalCreateNewVariation(false)
        refreshIndicator()
      }
    }

    const applyRangeChange = async (lowerValue: string, upperValue: string) => {
      const result = await updateRangeSelected({
        rangeSelected: `[${lowerValue},${upperValue})`,
        entityId: indicatorId,
        entityType: 'calculated_indicator'
      })
      if (result) {
        refreshIndicatorNoClose()
      }
      return result
    }

    // const analyseWithAI = async () => {
    //   const exportName = `${fetchedIndicatorParameters.current.title}.pdf`

    //   const combineDataForExport = () => {
    //     if (!fetchedDataTransformed || !fetchedIndicatorParameters) return null

    //     const description =
    //       'File description: ' + fetchedIndicatorParameters.current?.meaning
    //     const data = fetchedDataTransformed.current?.data.map((item) => {
    //       const titles = fetchedAllIndicators.current.find(
    //         (indicator) => indicator.fid === item.title
    //       )
    //       const title = titles ? titles.title : item.title

    //       return {
    //         title,
    //         data: item.values
    //       }
    //     })

    //     return { description, data }
    //   }

    //   const combinedData = combineDataForExport()

    //   if (combinedData) {
    //     const doc = new jsPDF() // eslint-disable-line
    //     doc.setFontSize(12)

    //     doc.text(combinedData.description, 10, 10)

    //     let y = 20
    //     combinedData.data.forEach((item) => {
    //       doc.text(item.title + ': ' + JSON.stringify(item.data), 10, y)
    //       y += 10
    //     })

    //     const blob = doc.output('blob')

    //     const file = new File([blob], exportName, {
    //       type: 'application/pdf'
    //     })
    //     files.current = [file]
    //     setDetailsTabMode('ai')
    //     return true
    //   }
    //   return false
    // }

    const handleSwitchPrimaryIndicator = async (fid: string) => {
      // find indicator in allIndicatorsFetched
      const indicator = fetchedAllIndicators.current.find(
        (ind) => ind.fid === fid
      )

      if (!indicator || indicator.type !== 'calculated') {
        return false
      }

      const update = await togglePrimarySettingCalculatedIndicator({
        base: indicatorId,
        fid
      })

      if (!update) {
        return false
      }

      refreshIndicator()

      return true
    }

    // const applyMappedCellWidthChange = (e: any) => {
    //   if (
    //     e.target.value >= 20 &&
    //     e.target.value <= 150 &&
    //     e.target.value.toString().slice(0, 1) !== '0' &&
    //     !isNaN(e.target.value)
    //   ) {
    //     const value = e.target.value
    //     setDataMappedCellWidth(value)
    //     const tickWidth = 17 * (value / 50)

    //     // Assuming 'value' and 'tickWidth' are defined and have numeric types
    //     // 1. Set width for elements with classes .date-tick and .tick
    //     const ticks: any = document.querySelectorAll('.date-tick, .tick')
    //     ticks.forEach(
    //       (tick: { style: { width: string } }) =>
    //         (tick.style.width = `${value}px`)
    //     )

    //     // 2. Set min-width for elements with classes .date-tick and .tick
    //     ticks.forEach(
    //       (tick: { style: { minWidth: string } }) =>
    //         (tick.style.minWidth = `${value}px`)
    //     )

    //     // 3. Set padding-left to 0 for elements with the selector .ticks-entry .ticks span
    //     const spanElements: any = document.querySelectorAll(
    //       '.ticks-entry .ticks span'
    //     )
    //     spanElements.forEach(
    //       (span: { style: { paddingLeft: string } }) =>
    //         (span.style.paddingLeft = '0')
    //     )

    //     // 4. Set width for images inside elements with the class .tick
    //     const tickImages: any = document.querySelectorAll('.tick img')
    //     tickImages.forEach(
    //       (img: any) =>
    //         (img.style.width = `${tickWidth > 17 ? 17 : tickWidth}px`)
    //     )
    //   }
    // }

    const handleCreateForecast = async () => {
      const res = await createNewForecast(indicatorId)

      if (!res) {
        return false
      }

      // res = await addIndicatorToShown(res)

      window.switchFunctions.forecast(res)
      return true
    }

    const handleExportData = () => {
      try {
        if (
          !fetchedDataTransformed ||
          !fetchedDataTransformed.current.data ||
          !fetchedDataTransformed.current.data[0]
        ) {
          return false
        }
        const title =
          fetchedIndicatorParameters.current?.title || 'Default Title'

        const dates = fetchedDataTransformed.current.data[0].dates
        const values = fetchedDataTransformed.current.data[0].values
        const data = values.map((item: any, index: number) => {
          return {
            date: dates[index],
            title: item
          }
        })

        exportToExcel(
          data,
          `${title} - ${new Date()
            .toLocaleDateString()
            .split('/')
            .join('-')
            .slice(0, 10)}`
        )

        return true
      } catch (err: any) {
        console.error(err)
      }
      return false
    }

    const load = async () => {
      if (pageLoading) return

      await fetchIndicator(indicatorId)
    }

    useEffect(() => {
      load()
    }, [indicatorId])

    useEffect(() => {
      setChangesToInputs(checkChanges())
    }, [
      offsetStarting,
      selectedIndicator,
      delayedInitialDate,
      delayedStartDate,
      baseCompositeIndicatorId
    ])

    if (pageLoading) return <Spinner />
    else if (!accessAllowed && !pageLoading) return <AccessDenied />
    else if (accessAllowed && !pageLoading && fetchedIndicatorParameters) {
      return (
        <div className="entity calculated">
          <EntityHeader
            subtitle="Calculated Indicator"
            ownerInfo={ownerInfo}
            title={fetchedIndicatorParameters.current?.title}
            description={fetchedIndicatorParameters.current?.short_description}
            titleClick={() => window.switchFunctions.indicator(indicatorId)}
          />
          <div className="entity-blocks">
            <div className="container w-12">
              <div className="entity-parameters justify-content-start">
                <AddRemoveSelectedPopup
                  entityType="calculated"
                  entityId={indicatorId}
                  fetchedAllIndicators={fetchedAllIndicators.current}
                  refreshEntity={refreshIndicator}
                  resetModals={resetModals}
                  initialSelectedEntities={
                    fetchedIndicatorParameters.current?.ids_included || []
                  }
                />
                {!readOnly && (
                  <FunctionalButton
                    className="inline no-btn default-text no-hover no-border"
                    functionToExecute={() => {
                      setModalCreateTrendline(true)
                    }}
                    noReturn
                    doesReset
                    initialButtonState={'Generate Trendline'}
                    iconPlusMode
                    collapsible
                  />
                )}
                {!readOnly && (
                  <FunctionalButton
                    className="inline no-btn default-text no-hover no-border"
                    functionToExecute={handleCreateForecast}
                    doesReset
                    initialButtonState={'Generate Forecast'}
                    iconPlusMode
                    collapsible
                  />
                )}
                <button
                  className="inline no-btn default-text no-hover no-border"
                  onClick={handleContextMenuDetails}
                >
                  <Icon3Dots />
                </button>
              </div>
            </div>

            <div className="container w-6 large no-scrollbar">
              <div className="entity-info" onClick={resetContextMenuDetails}>
                <div className="entity-parameters">
                  <TabbingSwitch
                    options={[
                      {
                        label: 'Details',
                        onClick: () => setDetailsTabMode('details'),
                        active: detailsTabMode === 'details'
                      },
                      {
                        label: 'Gap Analysis',
                        onClick: () => setDetailsTabMode('gapAnalysis'),
                        active: detailsTabMode === 'gapAnalysis'
                      },
                      {
                        label: 'Tags',
                        onClick: () => setDetailsTabMode('tags'),
                        active: detailsTabMode === 'tags',
                        exists: !readOnly
                      },
                      {
                        label: 'Sharing',
                        onClick: () => setDetailsTabMode('sharing'),
                        active: detailsTabMode === 'sharing',
                        exists: !readOnly
                      }
                    ]}
                    fit
                    className="no-border col-12"
                    numberVisible={5}
                  />
                </div>
                {detailsTabMode === 'details' && (
                  <textarea
                    className="description default-text"
                    contentEditable={false}
                    readOnly
                    value={
                      fetchedIndicatorParameters.current?.meaning ||
                      'No description provided'
                    }
                  />
                )}

                {detailsTabMode === 'gapAnalysis' && (
                  <GapAnalysis
                    indicators={allFetchedIndicators.current}
                    indicatorData={fetchedIndicatorData}
                    indicatorId={indicatorId}
                    refreshFunction={refreshIndicatorNoClose}
                  />
                )}

                <div
                  style={{
                    display: detailsTabMode === 'ai' ? 'block' : 'none'
                  }}
                  className="flex-row col-12"
                >
                  <ChatbotComponent
                    page="entity"
                    instructions="  Review this document elucidating the calculated indicator derived from other indicators or values, and furnish a thorough analysis of its contents. Assess the significance of each constituent indicator or value in the calculation process, and conduct a comparative evaluation among them. Illuminate any noteworthy features inherent in the calculated indicator."
                    assistantOn={true}
                    providedFiles={files.current}
                    clearFiles={() => (files.current = null)}
                  />
                </div>
                {detailsTabMode === 'tags' && (
                  <div className="description flex-row col-12">
                    <div className="entity-info-block col-12 default-text">
                      {!readOnly && (
                        <EntityTags
                          type="calculated"
                          allTags={userTags}
                          entityTags={
                            fetchedIndicatorParameters.current?.tags || []
                          }
                          entityId={indicatorId}
                          functionRefresh={refreshIndicatorNoClose}
                        />
                      )}
                    </div>
                  </div>
                )}
                {detailsTabMode === 'sharing' && (
                  <div className="description flex-row col-12">
                    <div className="entity-info-block col-12 default-text">
                      <button
                        className="secondary wide m-1"
                        onClick={handleExportData}
                      >
                        Export Data
                      </button>
                      <EntitySharingControls
                        entityType="calculated"
                        entityId={indicatorId}
                        ChannelContentPiece={
                          fetchedIndicatorParameters.current
                            ?.ChannelContentPiece || null
                        }
                        refreshFunction={refreshIndicatorNoClose}
                        initialDescription={
                          fetchedIndicatorParameters.current?.meaning
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="container w-6 large scroll no-scrollbar">
              <div className="entity-chart">
                {readOnly && <span>Chart Read Only</span>}
                {fetchedIndicatorData && fetchedIndicatorData.length > 0 && (
                  <HighChartsLinearChart
                    chartOptions={{
                      xAxisTitle: 'Month',
                      yAxisTitle: 'Value',
                      title: fetchedIndicatorParameters.current?.title,
                      mediumSize: true
                    }}
                    chartData={{
                      fetchedData: fetchedIndicatorData,
                      fullDates,
                      titles: includedIds.map((item: IIncludedId) => {
                        const matchedIndicator =
                          fetchedAllIndicators.current.find(
                            (indicator: any) => indicator.fid === item.fid
                          )

                        return {
                          [item.fid]: matchedIndicator?.title || ''
                        }
                      }),
                      filteredKeys: [...includedIds]
                        .filter((_) => _.chart)
                        .map((item) => item.fid),
                      deviations: allFetchedIndicators.current as ITrendline[]
                    }}
                    componentOptions={{
                      own:
                        !readOnly &&
                        fetchedIndicatorParameters &&
                        !fetchedIndicatorParameters.current?.admin_access,
                      parametersFetched: fetchedIndicatorParameters.current
                      // onlyDeviations: indicatorDataMode === 'deviations'
                    }}
                  />
                )}
              </div>
            </div>
            <div className="container w-12 fit">
              <div className="entity-parameters">
                {!readOnly && (
                  <div className="flex gap-2 middle">
                    <FunctionalButton
                      className="inline no-btn default-text no-hover no-border"
                      noReturn
                      functionToExecute={() => setModalCreateNewVariation(true)}
                      doesReset
                      initialButtonState={'Create a variation'}
                      iconPlusMode
                      combinedButtonTitle
                    />
                    {modalCreateNewVariation && (
                      <Fragment>
                        <input
                          type="text"
                          value={newVariationName}
                          onChange={(e) => setNewVariationName(e.target.value)}
                          placeholder="Variation Name"
                        />
                        <FunctionalButton
                          className="primary"
                          functionToExecute={handleCreateNewVariation}
                          initialButtonState="Submit"
                          iconSaveMode
                          doesReset
                          disabled={newVariationName.length === 0}
                        />
                        <FunctionalButton
                          className="destructive"
                          functionToExecute={() =>
                            setModalCreateNewVariation(false)
                          }
                          initialButtonState="Undo"
                          iconBackwardsMode
                          doesReset
                        />
                      </Fragment>
                    )}
                  </div>
                )}
                <ScrollTableButtons />
              </div>

              {fetchedDataTransformed &&
                fetchedDataTransformed.current.data &&
                fetchedDataTransformed.current.data.length > 0 && (
                  <div
                    className="container w-12 fit"
                    onClick={resetContextMenuDetails}
                  >
                    <div className="container w-12 scroll-x no-borders">
                      <DataTableNew
                        tableMode="medium"
                        fullDates={fullDates}
                        readOnly={readOnly}
                        data={fetchedDataTransformed.current}
                        filteredKeys={includedIds
                          .filter((_) => _.chart)
                          .map((item) => item.fid)}
                        chartKeys={includedIds
                          .filter((_) => _.chart)
                          .map((item) => item.fid)}
                        fetchedIndicators={allFetchedIndicators.current}
                        dataMode="calculated"
                      />
                    </div>
                  </div>
                )}
            </div>
          </div>
          <CustomContextMenu
            contextMenuSettings={contextMenuDetails}
            menu={[
              {
                onClick: () => {
                  resetModals()
                  setModalIndicatorNameAndDescription(true)
                },
                title: 'Edit Details',
                noAccess: readOnly
              },
              {
                onClick: () => {
                  resetModals()
                  window.switchFunctions.upload()
                },
                title: 'Create/Clone Indicator'
              },
              {
                onClick: async () => {
                  resetModals()
                  await updateIndicatorDataMode({
                    entityFid: indicatorId,
                    entityType: 'calculated',
                    newMode:
                      indicatorDataMode === 'values' ? 'deviations' : 'values'
                  })
                  refreshIndicator()
                },
                title:
                  indicatorDataMode === 'values'
                    ? 'Deviations Data Mode'
                    : 'Values Data Mode'
              },
              {
                onClick: () => {
                  resetModals()
                  setShowRelationalTree(true)
                },
                title: 'Show Relational Tree'
              },
              {
                onClick: () =>
                  modifyCollectionEntries({
                    entityType: 'calculated',
                    entityId: indicatorId,
                    action: isCurrentIndicatorFavourite ? 'remove' : 'add',
                    entityName: fetchedIndicatorParameters.current?.title,
                    collectionId: favourites === undefined ? 0 : favourites.id
                  }),
                title: isCurrentIndicatorFavourite
                  ? 'Remove from My Library'
                  : 'Add to My Library',
                functionalButtonSettings: {
                  exists: true,
                  refreshFunction: () => refreshIndicatorNoClose(true),
                  doesReset: true
                },
                noAccess: fetchedIndicatorParameters.current?.is_own
              },
              {
                onClick: async () =>
                  await updateEntityInverse({
                    fid: fetchedIndicatorParameters.current?.fid || '',
                    type: 'calculated'
                  }),
                title: fetchedIndicatorParameters.current?.inverse
                  ? 'Toggle Not Inverse'
                  : 'Toggle Inverse',
                functionalButtonSettings: {
                  exists: true,
                  refreshFunction: () => refreshIndicatorNoClose(true),
                  doesReset: true
                }
              },
              {
                onClick: () => {
                  resetModals()
                  setModalEntityRange(true)
                },
                noAccess: readOnly,
                title: 'Edit Range'
              },
              {
                onClick: () => {
                  resetModals()
                  setDetailsTabMode('ai')
                },
                title: 'AI'
              },
              {
                onClick: () => {
                  resetModals()
                  setModalDeleteIndicator(true)
                },
                title: 'Delete',
                noAccess: readOnly
              }
            ]}
          />

          {modalCreateTrendline && (
            <PopupModal
              isOpen={modalCreateTrendline}
              onClose={() => setModalCreateTrendline(false)}
              title="Create Trendline"
              size="largest"
              handleSubmit={() => undefined}
              saveButtonExists={false}
              inner
              noChanges
              className="p-0"
            >
              <CreateTrendline
                fetchedDataTransformed={
                  fetchedDataTransformed.current.data.filter(
                    (entry) => entry.title === indicatorId
                  )[0]
                }
                fetchedIndicatorParameters={fetchedIndicatorParameters.current}
                indicatorId={indicatorId}
                fullDates={fullDates}
                source="calculated"
                source_fid={indicatorId}
                closeModal={resetModals}
              />
            </PopupModal>
          )}

          {modalIndicatorEditData && !readOnly && (
            <PopupModal
              isOpen={modalIndicatorEditData && !readOnly}
              onClose={() => setModalIndicatorEditData(false)}
              title="Edit Indicator Details"
              size={'large'}
              handleSubmit={() => undefined}
              saveButtonExists={false}
              noChanges={true}
            >
              <UploadPage
                fullFetchedIndicatorsParameters={allFetchedIndicators.current}
                refreshFunction={refreshIndicator}
                singleIndicatorMode={{
                  exists: true,
                  indicatorId
                }}
                mode="upload"
              />
            </PopupModal>
          )}
          {modalEntityRange && (
            <PopupModal
              isOpen={modalEntityRange && !readOnly}
              onClose={() => setModalEntityRange(false)}
              title="Calculated Range"
              size="medium"
              handleSubmit={() => undefined}
              saveButtonExists={false}
              customMessage="If you leave this page, the changes will not be saved."
              noChanges={true}
            >
              <RangeSlider2
                dataArray={fullDates}
                initialDetails={transformFetchedRangedDetailsScenariotoRangeInputs(
                  fetchedIndicatorParameters.current?.range_complete || ''
                )}
                selectedDetails={selectedDataBoundaries}
                dateSliderMode={true}
                iconMode={'save'}
                customStyles={{
                  width: 400,
                  padding: '6px 20px 6px 5px'
                }}
                onApply={applyRangeChange}
              />
            </PopupModal>
          )}
          {modalDeleteIndicator && !readOnly && (
            <PopupModal
              isOpen={modalDeleteIndicator && !readOnly}
              onClose={() => setModalDeleteIndicator(false)}
              title="Are you sure you want to delete this indicator?"
              size="small"
              handleSubmit={() => undefined}
              saveButtonExists={false}
              inner
            >
              <div className="flex-row center middle margin-1">
                <FunctionalButton
                  className="destructive"
                  functionToExecute={async () =>
                    await deleteIndicatorByName(indicatorId)
                  }
                  initialButtonState={'Delete'}
                  refreshOnComplete={{
                    exists: true,
                    refreshFunction: () =>
                      window.switchFunctions.deleted(indicatorId)
                  }}
                />
              </div>
            </PopupModal>
          )}

          {modalIndicatorNameAndDescription && (
            <PopupModal
              isOpen={modalIndicatorNameAndDescription && !readOnly}
              onClose={resetModals}
              title="Edit Indicator Details"
              size="large"
              handleSubmit={() => undefined}
              inner
            >
              <RenameMajorEntity
                functionToExecute={handleRename}
                title={fetchedIndicatorParameters.current.title}
                description={fetchedIndicatorParameters.current.meaning}
                short_description={
                  fetchedIndicatorParameters.current.short_description
                }
              />
            </PopupModal>
          )}
          {showRelationalTree && (
            <PopupModal
              isOpen={showRelationalTree}
              onClose={() => setShowRelationalTree(false)}
              title="Relational Tree"
              size="large"
              handleSubmit={() => undefined}
              noChanges={true}
              inner
            >
              {!isEmpty(
                fetchedIndicatorParameters.current?.relational_tree
              ) && (
                <MyTreeChart
                  jsonData={
                    fetchedIndicatorParameters.current?.relational_tree || ''
                  }
                  dataOptions={{
                    titles: generateTitlesForFidsInAString(
                      JSON.stringify(
                        fetchedIndicatorParameters.current?.relational_tree
                      ),
                      fetchedAllIndicators.current
                    )
                  }}
                />
              )}
            </PopupModal>
          )}
          {modalEditConfiguration.open && (
            <PopupModal
              isOpen={modalEditConfiguration.open}
              onClose={() =>
                setModalEditConfiguration({ open: false, fid: '' })
              }
              title={
                modalEditConfiguration.fid === indicatorId
                  ? 'Primary Indicator'
                  : 'Non-primary Variant'
              }
              size="large"
              handleSubmit={() => undefined}
              titleButton={{
                exists: !readOnly && modalEditConfiguration.fid !== indicatorId,
                title: 'Set as primary',
                onClick: async () =>
                  await handleSwitchPrimaryIndicator(
                    modalEditConfiguration.fid
                  ),
                class: 'primary wide'
              }}
              noChanges={true}
              inner
            >
              <div className="col-12">
                {/* {indicatorComputationMode === 'composite' && (
                          <div className="flex m-2 gap-3 mt-3">
                            <h5 className="m-0">Select the Base Indicator</h5>
                            <select
                              className="wider"
                              value={baseCompositeIndicatorId}
                              onChange={(e) =>
                                setBaseCompositeIndicatorId(e.target.value)
                              }
                            >
                              {isEmpty(fetchedIndicatorParameters.equation) && (
                                <option disabled value="">
                                  No Indicators in Equation
                                </option>
                              )}
                              {!isEmpty(
                                fetchedIndicatorParameters.equation
                              ) && (
                                <option disabled value="">
                                  Equation Base Indicator
                                </option>
                              )}
                              {!isEmpty(fetchedIndicatorParameters.equation) &&
                                fetchedIndicators
                                  .filter(
                                    (indicator: ICalculatedIndicator) =>
                                      fetchedIndicatorParameters.equation &&
                                      fetchedIndicatorParameters.equation.includes(
                                        indicator.fid
                                      )
                                  )
                                  .map((indicator: ICalculatedIndicator) => (
                                    <option
                                      key={indicator.fid}
                                      value={indicator.fid}
                                    >
                                      {indicator.title}
                                    </option>
                                  ))}
                              {fetchedIndicators.length === 0 && (
                                <option disabled value="">
                                  No indicators in equation
                                </option>
                              )}
                            </select>
                          </div>
                        )} */}

                {indicatorComputationMode === 'offset' && (
                  <div className="flex gap-3 mx-2 mt-3 flex-wrap">
                    <p className="m-0">Offset by (months)</p>
                    <input
                      type="number"
                      min={1}
                      className="center narrow"
                      value={offsetStarting}
                      placeholder="Number of months"
                      onChange={(e) => setOffsetStarting(e.target.value)}
                    />
                    <select
                      className="wider"
                      value={selectedIndicator}
                      onChange={(e) => setSelectedIndicator(e.target.value)}
                    >
                      <option value="">Select the Indicator to Offset</option>
                      {allFetchedIndicators.current
                        .filter(filterBasedOnMode)
                        .filter((indicator) => indicator.fid !== indicatorId)
                        .map((indicator) => (
                          <option key={indicator.fid} value={indicator.fid}>
                            {indicator.title}
                          </option>
                        ))}
                    </select>
                    {changesToInputs && (
                      <FunctionalButton
                        className="primary"
                        functionToExecute={handleUpdateComputationalMode}
                        initialButtonState="Submit"
                        iconSaveMode
                        combinedButtonTitle
                        refreshOnComplete={{
                          exists: true,
                          refreshFunction: refreshIndicator
                        }}
                        doesReset
                      />
                    )}
                  </div>
                )}
                {(indicatorComputationMode === 'delayed' ||
                  indicatorComputationMode === 'delayedTotal') && (
                  <div className="flex m-2 gap-3 mt-3">
                    <h5 className="m-0">Select the Initial Date</h5>
                    <input
                      type="date"
                      value={delayedInitialDate}
                      onChange={(e) => setDelayedInitialDate(e.target.value)}
                    />
                    <br />
                    <h5 className="m-0">Select the Start Date</h5>
                    <input
                      type="date"
                      value={delayedStartDate}
                      onChange={(e) => setDelayedStartDate(e.target.value)}
                    />
                  </div>
                )}
                {calculatorVisible && (
                  <div className="col-12">
                    <h3 className="col-12">Adjust the equation to calculate</h3>
                    <CombineIndicatorFormula
                      indicators={fetchedAllIndicators.current.filter(
                        filterBasedOnMode
                      )}
                      indicatorId={modalEditConfiguration.fid}
                      updateEquationFunction={handleUpdateEquationByFid}
                      refreshFunction={refreshIndicator}
                      computationMode={indicatorComputationMode}
                      dataMode={indicatorDataMode}
                      changes={modeChanged}
                    />
                  </div>
                )}

                {fetchedIndicatorParameters.current?.computation_mode.includes(
                  'trendline'
                ) && (
                  <div className="col-12 mt-4 flex center middle">
                    <FunctionalButton
                      className="primary wide"
                      functionToExecute={handleUpdateComputationalMode}
                      initialButtonState="Calculate"
                      iconSaveMode
                      combinedButtonTitle
                      refreshOnComplete={{
                        exists: true,
                        refreshFunction: refreshIndicator
                      }}
                      doesReset
                    />
                  </div>
                )}
                <hr />
                {modalEditConfiguration.fid !== indicatorId && (
                  <div className="col-12 flex middle gap-3">
                    <FunctionalCheckbox
                      title="Initiate Delete This Variant"
                      state={false}
                      functionToExecute={() => undefined}
                      color="danger-o"
                      followup={{
                        title: 'Delete',
                        class: 'destructive',
                        function: async () =>
                          await deleteIndicatorByName(
                            modalEditConfiguration.fid
                          ),
                        refreshFunction: refreshIndicator
                      }}
                    />
                  </div>
                )}
              </div>
            </PopupModal>
          )}
        </div>
      )
    } else return <AccessDenied />
  } catch (error) {
    console.error('Error rendering indicator page:', error)
    return <AccessDenied />
  }
}

export default CalculatedIndicator
